import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { useParams } from "react-router-dom";
import {
  ExamLayoutInterface,
  ExamLayoutObjectInterface,
  HomeTabEnum,
  IconTypeEnum,
  ParamsStringEnum,
} from "interfaces";
import { ClassRoomContext, ExamContext, StudentGroupContext } from "context";
import { MyToggleButton, TableComponent } from "components";
import {
  createLayoutHandler,
  getClassroomLayoutObject,
  getIconsHandler,
} from "helpers";
import { useReactToPrint } from "react-to-print";

function ExamDetailsPage() {
  const { exam_id } = useParams<ParamsStringEnum>();
  const [activeTab, setActiveTab] = useState("attendance");

  const { details, getDetailsHandler, isDetailsLoading } =
    useContext(ExamContext);
  useEffect(() => {
    if (exam_id) {
      getDetailsHandler(exam_id);
    }
  }, [exam_id]);
  const [labelState, setLabelState] = useState({
    "Student Name": true,
    "Symbol Number": true,
    "Page Number": false,
    Subject: true,
    Signature: true,
    Language: false,
  });

  const examLayoutObject = getClassroomLayoutObject(details?.exam_layouts);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const PrintIcon = getIconsHandler(IconTypeEnum.PRINT);
  return (
    <PageTemplate
      isFullWidth
      isLoading={isDetailsLoading}
      title={`${details?.name || "Exam details"}`}
      backPath={PageLinks.home(HomeTabEnum.EXAMS)}
      rightChildren={
        details &&
        !isDetailsLoading && (
          <div>
            <div
              style={{
                backgroundImage:
                  "linear-gradient(to right top, #0b297b, #691b81, #a60073, #d20055, #eb122b)",
              }}
              className={
                "flex items-center text-white gap-2  rounded-md px-3 py-1 cursor-pointer bg-gray-100 shadow-sm"
              }
              onClick={handlePrint}
            >
              <PrintIcon />
              <span>Print</span>
            </div>
          </div>
        )
      }
    >
      <div className={"flex flex-col items-center gap-5 w-full  hideScroll "}>
        <div
          className={
            "bg-gray-50 grid grid-cols-2 rounded-md p-1 text-[14px] mx-8 gap-2 shadow-sm w-[500px] sm:w-[250px]"
          }
        >
          {[
            { name: "attendance", label: "Attendance" },
            { name: "classroomLayout", label: "Classroom Layout" },
          ]?.map((e, key) => {
            const isActive = e?.name === activeTab;
            return (
              <div
                onClick={() => {
                  setActiveTab(e?.name);
                }}
                className={`text-center cursor-pointer  py-1 px-3 text-[16px] sm:text-[12px] whitespace-nowrap font-semibold ${isActive ? "bg-[#08B195] text-white rounded-md" : ""}`}
                key={key}
              >
                {e?.label}
              </div>
            );
          })}
        </div>

        {activeTab === "attendance" && (
          <div className={"flex  gap-7 sm:gap-2 flex-wrap"}>
            {Object.keys(labelState).map((e) => {
              return (
                <div
                  key={e}
                  onClick={() => {
                    let tempState = { ...labelState };
                    tempState[e] = !tempState[e];
                    setLabelState(tempState);
                  }}
                  className={"flex items-center gap-1 cursor-pointer"}
                >
                  <MyToggleButton
                    value={labelState[e]}
                    changeHandler={() => {}}
                  />{" "}
                  {e}
                </div>
              );
            })}
          </div>
        )}
        <div
          ref={componentRef}
          className={"px-2  h-full w-full sm:overflow-x-scroll hideScroll"}
        >
          {activeTab === "attendance" ? (
            <RenderExamAttendance
              labelState={labelState}
              examLayout={details?.exam_layouts}
            />
          ) : (
            details?.class_rooms?.map((classroom, key) => {
              return (
                <RenderClassLayout
                  examLayoutObject={examLayoutObject}
                  classId={classroom}
                  key={key}
                />
              );
            })
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

const RenderExamAttendance = ({
  examLayout,
  labelState,
}: {
  examLayout: ExamLayoutInterface[];
  labelState: {
    "Student Name": boolean;
    "Symbol Number": boolean;
    "Page Number": boolean;
    Subject: boolean;
    Signature: boolean;
    Language: boolean;
  };
}): any => {
  const { listObject: ClassroomObject } = useContext(ClassRoomContext);
  const { listObject: GroupObject } = useContext(StudentGroupContext);

  const Content = useMemo(() => {
    let ClassRoomData = {};
    examLayout?.forEach((e) => {
      ClassRoomData[e?.class_id] = {
        ...(ClassRoomData[e?.class_id] || {}),
        [e.group_id]: [...(ClassRoomData[e?.class_id]?.[e?.group_id] || []), e],
      };
    });

    return ClassRoomData;
  }, [examLayout]);
  return (
    <div
      className={"  w-full h-full"}
      style={{
        width: "210mm",
      }}
    >
      {Object.keys(Content).map((classId) => {
        return (
          <div key={classId} className={"flex flex-col gap-10"}>
            <div
              className={"grid grid-cols-3 gap-5"}
              style={{
                paddingTop: "5mm",
              }}
            >
              {[
                { label: "Invigilator", value: "" },
                { label: "Room", value: ClassroomObject[classId]?.name },
                { label: "Date", value: "" },
              ]?.map((e, key) => {
                return (
                  <div key={key} className={"flex items-end gap-2"}>
                    <span className={"w-full flex-1 font-bold"}>
                      {e?.label}:
                    </span>
                    <div
                      className={
                        "w-full border-b-2 border-black border-dotted text-center"
                      }
                    >
                      {e?.value}
                    </div>
                  </div>
                );
              })}
            </div>
            {Object.keys(Content[classId])?.map((groupId) => {
              return (
                <div key={groupId} className={"flex flex-col gap-3"}>
                  <div className={"text-center text-[14px] font-bold"}>
                    {GroupObject?.[groupId]?.name || "-"}
                  </div>
                  <TableComponent
                    column={[
                      {
                        title: "SN.",
                        render(renderData: ExamLayoutInterface, key: number) {
                          return <span>{key + 1}.</span>;
                        },
                      },
                      labelState?.["Student Name"] && {
                        title: "Student Name",
                        render(renderData: ExamLayoutInterface, key: number) {
                          return <span>{renderData?.student_name}</span>;
                        },
                      },
                      labelState?.["Symbol Number"] && {
                        title: "Symbol No.",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.symbol_no}</span>;
                        },
                      },
                      labelState?.["Page Number"] && {
                        title: "Paper Number",
                        render(renderData: ExamLayoutInterface) {
                          return <span></span>;
                        },
                      },
                      labelState?.Subject && {
                        title: "Subject",
                        render(renderData: ExamLayoutInterface) {
                          return <span></span>;
                        },
                      },
                      labelState?.Signature && {
                        title: "Signature",
                        render(renderData: ExamLayoutInterface) {
                          return <span></span>;
                        },
                      },
                      labelState?.Language && {
                        title: "Language",
                        render(renderData: ExamLayoutInterface) {
                          return <span></span>;
                        },
                      },
                    ]}
                    dataSource={Content[classId][groupId]}
                  />
                </div>
              );
            })}
            <div
              style={{
                pageBreakAfter: "always",
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

const RenderClassLayout = ({
  classId,
  examLayoutObject,
}: {
  classId: string;
  examLayoutObject: ExamLayoutObjectInterface;
}): any => {
  const { listObject } = useContext(ClassRoomContext);
  const classDetails = listObject[classId];
  const { columnName, layout } = createLayoutHandler(
    classDetails?.class_room_layout || [],
    classDetails?.column_count,
  );
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setRows(new Array(classDetails?.row_count).fill("").map((e, key) => key));
    setColumns(
      new Array(classDetails?.column_count).fill("").map((e, key) => key),
    );
  }, [classDetails?.row_count, classDetails?.column_count]);

  const NUMBER_OF_ROWS = 11;
  const NUMBER_OF_COLUMNS = 2;

  const Content = useMemo(() => {
    let Pages = [];
    let tempCols = [...columns];
    while (tempCols.length > 0) {
      let tempRows = [...rows];
      let currentColumns = tempCols.slice(0, NUMBER_OF_COLUMNS);
      while (tempRows?.length > 0) {
        let currentRows = tempRows.slice(0, NUMBER_OF_ROWS);
        let details = (
          <div
            style={{
              height: "295mm",
              width: "210mm",
              paddingTop: "5mm",
            }}
            className={"flex flex-col gap-5 bg-white "}
          >
            <div className={"text-center py-2 font-bold"}>
              {classDetails?.name || "-"}
            </div>
            <div className={"grid grid-cols-[45px_auto] gap-2 w-full  "}>
              <div className={"flex flex-col pt-[40px] gap-2"}>
                {currentRows?.map((rowKey, key) => {
                  return (
                    <div
                      className={
                        "flex items-center justify-center  h-[80px] text-[14px] text-gray-500 bg-gray-100"
                      }
                      key={key}
                    >
                      R-{rowKey + 1}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  gridTemplateColumns: new Array(NUMBER_OF_COLUMNS)
                    .fill("")
                    .map((e) => "1fr")
                    .join(" "),
                }}
                className={`grid  w-full gap-2 pr-2`}
              >
                {currentColumns?.map((currentColumnValue) => {
                  return (
                    <div key={currentColumnValue} className={"flex-1"}>
                      <div className={"text-center font-bold"}>
                        {columnName?.[currentColumnValue] || ""}
                      </div>
                      {/*seats*/}
                      <div
                        className={
                          "flex flex-col items-center flex-1 gap-2 mt-4 h-full"
                        }
                      >
                        {tempRows?.map(
                          (currentRowValue: number, rKey: number) => {
                            const seatCount =
                              layout[currentColumnValue][currentRowValue];
                            if (!seatCount || seatCount == 0)
                              return <div className={"h-[80px]"}></div>;
                            if (rKey > currentRows?.length - 1)
                              return <div className={"h-[80px]"}></div>;
                            return (
                              <div
                                key={rKey}
                                className={
                                  "flex  h-[80px]  w-full border divide-x"
                                }
                              >
                                {new Array(seatCount)
                                  ?.fill(" ")
                                  .map((e, benchIndex) => {
                                    const details =
                                      examLayoutObject?.[classId]?.[
                                        currentColumnValue + 1
                                      ]?.[currentRowValue + 1]?.[
                                        benchIndex + 1
                                      ];

                                    return (
                                      <div
                                        className={
                                          "flex flex-col  items-center justify-center h-full w-full text-[12px] text-center "
                                        }
                                      >
                                        {details ? (
                                          <>
                                            <div
                                              className={
                                                "flex items-center justify-center font-bold h-[30%] border-b w-full whitespace-nowrap"
                                              }
                                            >
                                              {details?.symbol_no}
                                            </div>
                                            <div
                                              className={
                                                "flex items-center justify-center h-[70%] text-left  "
                                              }
                                            >
                                              {details?.student_name}
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
        Pages.push(details);
        tempRows = tempRows.slice(NUMBER_OF_ROWS);
      }
      tempCols = tempCols.slice(NUMBER_OF_COLUMNS);
    }
    return Pages;
  }, [rows]);

  return Content.map((e, key) => (
    <>
      {e}
      {key !== Content.length - 1 && (
        <div
          key={key}
          style={{
            pageBreakAfter: "always",
          }}
        ></div>
      )}
    </>
  ));
};
export default ExamDetailsPage;
