import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { useParams } from "react-router-dom";
import { HomeTabEnum, ParamsStringEnum } from "interfaces";
import { ClassRoomContext } from "context";
import { RoomLayoutContent } from "content";

function ClassDetailsPage() {
  const { class_id } = useParams<ParamsStringEnum>();

  const {
    details: classDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(ClassRoomContext);

  useEffect(() => {
    if (class_id) {
      getDetailsHandler(class_id);
    }
  }, [class_id]);

  const Details = [
    {
      name: "Total Capacity",
      count: classDetails?.total_space,
    },
    {
      name: "Total Column",
      count: classDetails?.column_count,
    },
    {
      name: "Total Row",
      count: classDetails?.row_count,
    },
  ];
  return (
    <PageTemplate
      isLoading={isDetailsLoading}
      title={`${classDetails?.name || "Classroom Details"}`}
      backPath={PageLinks.home(HomeTabEnum.ROOMS)}
    >
      <div className={"flex flex-col gap-5"}>
        <div className={"grid grid-cols-3 gap-2"}>
          {Details?.map((details, key) => {
            return (
              <div
                className={
                  "bg-white text-primary text-center border text-[14px] rounded-md p-2"
                }
              >
                <div className={"text-[12px]"}>{details?.name}</div>
                <div className={"font-bold"}>{details?.count}</div>
              </div>
            );
          })}
        </div>
        <RoomLayoutContent
          classRoomLayout={classDetails?.class_room_layout}
          column_count={classDetails?.column_count}
          row_count={classDetails?.row_count}
        />
      </div>
    </PageTemplate>
  );
}

export default ClassDetailsPage;
