import React, { useContext, useEffect } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PageLinks } from "routes";
import { DataContext } from "context";
import { useApplicationHook } from "useHooks";
import { getIconsHandler } from "helpers";
import {
  IconTypeEnum,
  ParamsStringEnum,
  ResetPasswordParamsEnum,
} from "interfaces";

function ForgotPasswordValidatePage() {
  const {
    handlers: { validatePasswordHandler },
  } = useContext(DataContext);
  const [query] = useSearchParams();
  const params: any = useParams<ParamsStringEnum>();
  const navigate = useNavigate();
  const token = query.get("token");
  const userId = query.get("userId");
  const email = query.get("email");
  const BackIcon = getIconsHandler(IconTypeEnum.BACK);
  const pageType: ResetPasswordParamsEnum = params?.type;

  const onSubmitHandler = (values: any) => {
    validatePasswordHandler(
      values,
      () => {
        navigate({
          pathname: PageLinks.resetPassword.setPassword,
          search: `?token=${token}&userId=${userId}`,
        });
      },
      (message) => {
        navigate({
          pathname: PageLinks.resetPassword.custom_message(
            ResetPasswordParamsEnum.VALIDATION_ERROR,
          ),
        });
      },
    );
  };
  const { fullHeight } = useApplicationHook();

  useEffect(() => {
    if (token && userId && pageType == ResetPasswordParamsEnum.VALIDATE_TOKEN) {
      onSubmitHandler({ token, user_id: userId });
    }
  }, [token, userId, pageType]);
  const details = (() => {
    let title = "";
    let message = "";
    switch (pageType) {
      case ResetPasswordParamsEnum.PASSWORD_RESET_SUCCESSFULLY:
        title = "Password reset successfully";
        message = `Your password  has been successfully reset.`;
        break;
      case ResetPasswordParamsEnum.VALIDATION_ERROR:
        title = "Validation error";
        message = `Error on resetting password. Please try again.`;
        break;
      case ResetPasswordParamsEnum.VALIDATE_TOKEN:
        title = "Validating link";
        message = `Please wait. We are verifying password reset link.`;
        break;
      default:
        title = "Check your email";
        message = `We have sent password reset link to ${email}`;
        break;
    }
    return {
      title,
      message,
    };
  })();

  return (
    <div style={{ height: fullHeight }} className={"h-full w-full "}>
      <div className={"flex flex-col items-center gap-20 w-full"}>
        <div className={"w-full mt-2"}>
          <a href={"http://examseatplanner.com"} target={"_blank"}>
            <img
              src={"/img/logo/logo.svg"}
              className={"h-[60px] sm:h-[40px] object-contain"}
              alt={"icon"}
            />
          </a>
        </div>

        <div className={"flex flex-col gap-5 w-[400px] sm:w-full"}>
          <div
            className={"flex flex-col items-center text-center w-full gap-2 "}
          >
            <div className={"text-center  text-[20px] uppercase font-bold"}>
              {details?.title}
            </div>
            <div className={"text-[14px] text-gray-500"}>
              {details?.message}
            </div>
          </div>

          {pageType !== ResetPasswordParamsEnum.VALIDATE_TOKEN && (
            <div
              onClick={() => navigate(PageLinks.login)}
              className={
                "flex w-full justify-center cursor-pointer items-center gap-2 text-[14px] text-gray-500"
              }
            >
              <BackIcon />
              Back to login
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordValidatePage;
