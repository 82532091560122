import React, { useContext } from "react";

import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageLinks } from "routes";
import { DataContext } from "context";
import { useApplicationHook } from "useHooks";
import { Form, Formik, FormikBag } from "formik";
import { MyButton, MyPasswordField } from "components";
import { ResetPasswordParamsEnum } from "../interfaces";

function ForgotPasswordSetPasswordPage() {
  const {
    handlers: { setPasswordHandler },
  } = useContext(DataContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get("token");
  const userId = params.get("userId");
  const onSubmitHandler = (values: any, props: FormikBag<any, any>) => {
    setPasswordHandler(
      values,
      () => {
        navigate(
          PageLinks.resetPassword.custom_message(
            ResetPasswordParamsEnum.PASSWORD_RESET_SUCCESSFULLY,
          ),
        );
      },
      (message) => {
        PageLinks.resetPassword.custom_message(
          ResetPasswordParamsEnum.VALIDATION_ERROR,
        );
      },
    );
  };
  const { fullHeight } = useApplicationHook();

  return (
    <div style={{ height: fullHeight }} className={"h-full w-full "}>
      <Formik
        enableReinitialize
        onSubmit={onSubmitHandler}
        initialValues={{
          user_id: userId,
          token: token,
          new_password: "",
        }}
        validationSchema={yup.object().shape({
          token: yup.string().required(" "),
          new_password: yup.string().required(" "),
          user_id: yup.string().required(" "),
        })}
      >
        <Form
          className={
            " flex flex-col h-full w-full p-4 justify-between items-start"
          }
        >
          <div className={"flex flex-col items-center gap-20 w-full"}>
            <div className={"w-full"}>
              <a href={"http://examseatplanner.com"} target={"_blank"}>
                <img
                  src={"/img/logo/logo.svg"}
                  className={"h-[60px] sm:h-[40px] object-contain"}
                  alt={"icon"}
                />
              </a>
            </div>
            <div className={"flex flex-col gap-5 w-[400px] sm:w-full"}>
              <div
                className={"text-center  text-[20px] uppercase font-bold mb-3"}
              >
                Set New Password
              </div>

              <MyPasswordField
                name={"new_password"}
                label={"New Password"}
                isRequired
                placeholder={"Enter new password"}
              />
              <MyButton name={"Submit"} type={"submit"} />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ForgotPasswordSetPasswordPage;
