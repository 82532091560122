import React, { useContext, useEffect, useState } from "react";
import { useApplicationHook } from "useHooks";
import { MyButton } from "components";
import { DataContext, SystemContext } from "context";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";

function AppIntroModel() {
  const { fullHeight } = useApplicationHook();
  const {
    state: { isAuthenticated, profileDetails },
  } = useContext(SystemContext);
  const {
    handlers: { setAppSetUpHandler },
  } = useContext(DataContext);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (
      isAuthenticated &&
      profileDetails?.email_verified &&
      !profileDetails?.app_setup
    ) {
      setOpen(true);
      navigate(PageLinks.home());
    }
  }, [isAuthenticated, profileDetails, profileDetails]);
  if (!isOpen) return;
  return (
    <div
      style={{ height: fullHeight }}
      className={
        "flex items-center justify-center w-screen bg-black bg-opacity-50 fixed top-0 z-[99999]"
      }
    >
      <div
        className={
          "flex flex-col bg-[#04B799] text-white rounded-xl overflow-hidden w-[400px]"
        }
      >
        <div className={"p-2 px-4 text-white bg-[#08B195] text-center"}>
          <b className={"text-[18px] font-bold "}>
            Welcome to the Seat Planner 🔥
          </b>
        </div>
        <div className={"text-[14px] p-2 px-4 text-center"}>
          Easily organize and manage your exam seating.
        </div>
        <div className={"flex items-center justify-center gap-2 p-2"}>
          <MyButton
            name={"Let's Start"}
            onClick={() => {
              setOpen(false);
              setAppSetUpHandler();
            }}
            colorType={"white"}
            isOutline
          />
        </div>
      </div>
    </div>
  );
}

export default AppIntroModel;
