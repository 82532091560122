import SystemContextProvider, { SystemContext } from "./SystemContext";
import DataContextProvider, { DataContext } from "./DataContext";
import ClassRoomContextProvider, { ClassRoomContext } from "./ClassRoomContext";
import ExamContextProvider, { ExamContext } from "./ExamContext";
import FeedbackContextProvider, { FeedbackContext } from "./FeedbackContext";
import StudentGroupContextProvider, {
  StudentGroupContext,
} from "./StudentGroupContext";
import SchoolContextProvider, { SchoolContext } from "./SchoolContext";
import FileContextProvider, { FileContext } from "./FileContext";

const ContextProvider = ({ children }) => {
  return (
    <SystemContextProvider>
      <SchoolContextProvider>
        <ClassRoomContextProvider>
          <DataContextProvider>
            <ExamContextProvider>
              <StudentGroupContextProvider>
                <FileContextProvider>
                  <FeedbackContextProvider>{children}</FeedbackContextProvider>
                </FileContextProvider>
              </StudentGroupContextProvider>
            </ExamContextProvider>
          </DataContextProvider>
        </ClassRoomContextProvider>
      </SchoolContextProvider>
    </SystemContextProvider>
  );
};

export {
  ContextProvider,
  StudentGroupContext,
  ExamContext,
  DataContext,
  ClassRoomContext,
  SystemContext,
  FeedbackContext,
  SchoolContext,
  FileContext,
};
