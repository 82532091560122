import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { DataContext, SystemContext } from "context";
import { Form, Formik, FormikBag } from "formik";

import { MyButton, MyTextField } from "components";
import { PageLinks } from "../routes";
import { getIconsHandler } from "../helpers";
import { IconTypeEnum } from "../interfaces";

function VerifyEmailPage() {
  const {
    handlers: { resendEmailVerificationCodeHandler, verifyEmailHandler },
  } = useContext(DataContext);
  const {
    handlers: { getCurrentProfileHandler, logoutHandler },
  } = useContext(SystemContext);
  const navigate = useNavigate();
  const LogoutIcon = getIconsHandler(IconTypeEnum.LOGOUT);

  const onSubmitHandler = (values: any, props: FormikBag<any, any>) => {
    verifyEmailHandler(values, () => {
      navigate(PageLinks.home());
      getCurrentProfileHandler();
    });
  };

  return (
    <div className={"h-full w-full "}>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{
          code: "",
        }}
        validationSchema={yup.object().shape({
          code: yup.number().typeError(" ").required(" "),
        })}
      >
        <Form className={" flex flex-col  p-4 justify-between items-center"}>
          <div className={"w-full flex justify-between items-center"}>
            <img
              src={"/img/logo/logo.svg"}
              className={"h-[60px] object-contain"}
              alt={"icon"}
            />
            <div
              onClick={() => logoutHandler(true)}
              className={
                "flex items-center gap-1 bg-white  text-[14px]  cursor-pointer"
              }
            >
              <LogoutIcon className={"text-[18px]"} /> Logout
            </div>
          </div>
          <div className={"flex flex-col gap-40 w-[400px] mt-[20vh]"}>
            <div className={"flex flex-col gap-5 w-full"}>
              <div
                className={"text-center  text-[20px] uppercase font-bold mb-3"}
              >
                Verify your email
              </div>

              <MyTextField
                name={"code"}
                label={"Code"}
                type={"number"}
                isRequired
                placeholder={"Enter verification code"}
              />

              <MyButton name={"Submit"} type={"submit"} />
              <div className={"text-[14px] text-gray-500"}>
                Resend code?
                <span
                  className={"text-black cursor-pointer"}
                  onClick={() => resendEmailVerificationCodeHandler({})}
                >
                  {" "}
                  Send
                </span>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default VerifyEmailPage;
