import React, { useContext } from "react";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { ExamContext } from "context";
import { MyButton, MyMoreOptionButton, TableComponent } from "components";
import { ClassRoomInterface, HomeTabEnum, IconTypeEnum } from "interfaces";
import { useNavigate } from "react-router-dom";

function Exam() {
  const {
    lists: exams,
    getListsHandler,
    deleteHandler,
  } = useContext(ExamContext);
  const navigate = useNavigate();

  return (
    <PageTemplate
      title={"Exams"}
      rightChildren={
        <MyButton
          onClick={() => navigate(PageLinks.exam.create)}
          isOutline
          name={"Create"}
          iconType={IconTypeEnum.ADD}
        />
      }
      backPath={PageLinks.home(HomeTabEnum.EXAMS)}
    >
      <TableComponent
        onRowClickHandler={(data: ClassRoomInterface) => {
          navigate(PageLinks.exam.details(data.id));
        }}
        column={[
          {
            title: "SN.",
            render(renderData: ClassRoomInterface, key: number) {
              return <span>{key + 1}.</span>;
            },
          },
          {
            title: "Name",
            render(renderData: ClassRoomInterface) {
              return <span>{renderData?.name}</span>;
            },
          },
          {
            title: "",
            render(renderData: ClassRoomInterface) {
              return (
                <MyMoreOptionButton
                  options={[
                    {
                      name: "Edit",
                      handler() {
                        navigate(PageLinks.exam.edit(renderData?.id));
                      },
                    },
                    {
                      name: "Delete",
                      handler() {
                        deleteHandler(renderData?.id, () => {
                          getListsHandler();
                        });
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
        dataSource={exams}
      />
    </PageTemplate>
  );
}

export default Exam;
