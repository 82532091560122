import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Formik, useFormikContext } from "formik";
import {
  FormSubmitButtonsComponent,
  MyTextField,
  TableComponent,
} from "components";
import * as yup from "yup";
import { StudentGroupContext } from "context";
import { ParamsStringEnum, StudentInterface } from "interfaces";
import { PageLinks } from "routes";

function EditStudentPage() {
  const { group_id } = useParams<ParamsStringEnum>();

  const navigate = useNavigate();
  const { editGroupStudentHandler, getListsHandler } =
    useContext(StudentGroupContext);

  const submitHandler = (values: any) => {
    let tempStudents = [
      ...values?.students?.filter((e) => e?.name && e?.symbol_no),
    ];
    if (tempStudents?.length < 1) return;
    editGroupStudentHandler(tempStudents, () => {
      getListsHandler();
      navigate(PageLinks.studentGroup.details(group_id));
    });
  };

  return (
    <PageTemplate
      backPath={PageLinks.studentGroup.details(group_id)}
      title={"Add Students"}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={FormValues.initialValues()}
        validationSchema={FormValues.validationSchema}
      >
        {({ values }) => {
          return (
            <Form className={"flex flex-col gap-5 mb-20"}>
              <div className={"flex flex-col gap-2"}>
                <MyTextField
                  name={"studentNumbers"}
                  label={"Enter number of students"}
                  placeholder={"eg: 10"}
                  isRequired
                  type={"number"}
                />
              </div>
              <div className={"overflow-x-scroll"}>
                <TableComponent
                  column={[
                    {
                      title: "SN",
                      render(renderData: any, key: number) {
                        return <div>{key + 1}.</div>;
                      },
                    },
                    {
                      title: "Name",
                      render(renderData: any, key: number) {
                        return (
                          <MyTextField
                            name={`students.${key}.name`}
                            placeholder={"eg: Ram Nepali"}
                          />
                        );
                      },
                    },
                    {
                      title: "Symbol No.",
                      render(renderData: any, key: number) {
                        return (
                          <div className={"w-[150px]"}>
                            <MyTextField
                              name={`students.${key}.symbol_no`}
                              placeholder={"eg: 12994"}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  dataSource={values?.students}
                />
              </div>
              <FormListener group_id={group_id} />
              {values?.students?.length > 0 && (
                <FormSubmitButtonsComponent
                  cancelButton={{
                    handler() {
                      navigate(PageLinks.studentGroup.details(group_id));
                    },
                  }}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

function FormListener({ group_id }: { group_id: string }) {
  const { values, setFieldValue } = useFormikContext<any>();
  useEffect(() => {
    let totalNumber = parseInt(values?.studentNumbers);
    if (totalNumber > 0) {
      setFieldValue(
        "students",
        new Array(totalNumber).fill("").map((e) => {
          return FormValues.studentRows({ group_id });
        }),
      );
    }
  }, [values?.studentNumbers]);
  return <></>;
}

const FormValues = {
  studentRows: (values?: Partial<StudentInterface>) => {
    return {
      symbol_no: values?.symbol_no,
      name: values?.name,
      group_id: values?.group_id,
    };
  },
  initialValues: () => {
    return {
      studentNumbers: "",
      students: [],
    };
  },
  validationSchema: yup.object().shape({
    students: yup.array().of(
      yup.object().shape({
        // symbol_no: yup.string().required(" "),
        // name: yup.string().required(" "),
      }),
    ),
  }),
};
export default EditStudentPage;
