import React, { useContext, useEffect, useMemo, useState } from "react";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { getIconsHandler } from "helpers";
import { HomeTabEnum, IconTypeEnum, QueryStringEnum } from "interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ClassRoomContext,
  DataContext,
  ExamContext,
  StudentGroupContext,
  SystemContext,
} from "context";
import {
  BoxModal,
  FormSubmitButtonsComponent,
  MyButton,
  MyMoreOptionButton,
  MyTextField,
  MyToggleButton,
} from "components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FeedbackModel, VideoListModel } from "../content";
import { useApplicationHook } from "../useHooks";

function HomePage() {
  const ClassRoom = getIconsHandler(IconTypeEnum.CREATE_ROOM);
  const ExamIcon = getIconsHandler(IconTypeEnum.CREATE_EXAM);
  const GroupIcon = getIconsHandler(IconTypeEnum.CREATE_GROUP);
  const [query, setQuery] = useSearchParams();
  const activeTab = query.get("tab");
  const { dashboardStats } = useContext(DataContext);
  const {
    isLoading: isExamLoading,
    getListsHandler: getExamsHandler,
    lists: exams,
  } = useContext(ExamContext);
  const {
    isLoading: isRoomLoading,
    getListsHandler: getClassRoomHandler,
    lists: classRooms,
  } = useContext(ClassRoomContext);
  const {
    isLoading: isGroupLoading,
    getListsHandler: getGroupsHandler,
    lists: studentGroups,
  } = useContext(StudentGroupContext);

  const Menu = [
    {
      key: HomeTabEnum.EXAMS,
      name: "Exam",
      path: PageLinks.exam.create,
      icon: ExamIcon,
      color: "#E83350",
    },
    {
      key: HomeTabEnum.ROOMS,
      name: "Room",
      path: PageLinks.class.create,
      icon: ClassRoom,
      color: "#25CCF7",
    },

    {
      key: HomeTabEnum.GROUPS,
      name: "Group",
      path: PageLinks.studentGroup.create,
      icon: GroupIcon,
      color: "#53E0BC",
    },
  ];

  const Stats = [
    {
      name: "Total Capacity",
      count: dashboardStats?.seat_capacity || 0,
    },
    {
      name: "Active Exams",
      count: dashboardStats?.active_exam_count || 0,
    },
    {
      name: "Student Groups",
      count: dashboardStats?.student_groups || 0,
    },
  ];

  const ActiveList = (() => {
    switch (activeTab) {
      case HomeTabEnum.GROUPS:
        return (
          <TabContentComponent
            data={studentGroups}
            type={HomeTabEnum.GROUPS}
            isLoading={isGroupLoading}
          />
        );
      case HomeTabEnum.ROOMS:
        return (
          <TabContentComponent
            data={classRooms}
            type={HomeTabEnum.ROOMS}
            isLoading={isRoomLoading}
          />
        );
      default:
        return (
          <TabContentComponent
            type={HomeTabEnum.EXAMS}
            data={exams}
            isLoading={isExamLoading}
          />
        );
    }
  })();

  const { isSmScreen } = useApplicationHook();

  return (
    <PageTemplate>
      <div className={"flex flex-col gap-5 w-full h-full  "}>
        {isSmScreen && (
          <div className={"flex items-center justify-between gap-5 "}>
            <FeedbackModel />
            <VideoListModel />
          </div>
        )}
        <EditNameComponent />
        <StatsComponent Stats={Stats} />
        <CreateMenuComponent Menu={Menu} />
        <div className={"flex flex-col gap-2 items-center w-full "}>
          <TabComponent Menu={Menu} activeTab={activeTab} />
          <div className={"w-full h-full overflow-scroll"}>{ActiveList}</div>
        </div>
      </div>
    </PageTemplate>
  );
}

const TabContentComponent = ({
  data,
  isLoading,
  type,
  className,
}: {
  data: { name: string; id: string; active: boolean }[];
  isLoading?: boolean;
  type?: HomeTabEnum;
  className?: string;
}) => {
  const [status, setStatus] = useState("all");
  const Icon = (() => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        return getIconsHandler(IconTypeEnum.CLASSROOM);
      case HomeTabEnum.GROUPS:
        return getIconsHandler(IconTypeEnum.STUDENT_GROUP);
      case HomeTabEnum.EXAMS:
        return getIconsHandler(IconTypeEnum.EXAM);
    }
  })();
  const navigate = useNavigate();
  const {
    handlers: { getDashboardStatsHandler },
  } = useContext(DataContext);
  const {
    deleteHandler: deleteExamHandler,
    getListsHandler: getExamHandler,
    toggleStatusHandler: examToggleStatusHandler,
  } = useContext(ExamContext);
  const {
    deleteHandler: deleteGroupHandler,
    getListsHandler: getGroupHandler,
    toggleStatusHandler: studentGroupToggleStatusHandler,
  } = useContext(StudentGroupContext);
  const {
    deleteHandler: deleteRoomHandler,
    getListsHandler: getRoomHandler,
    toggleStatusHandler: classroomToggleStatusHandler,
  } = useContext(ClassRoomContext);
  useEffect(() => {
    setStatus("all");
  }, [type]);
  const onClickHandler = (id: string) => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        navigate(PageLinks.class.details(id));
        return;
      case HomeTabEnum.GROUPS:
        navigate(PageLinks.studentGroup.details(id));
        return;
      case HomeTabEnum.EXAMS:
        navigate(PageLinks.exam.details(id));
        return;
    }
  };
  const onDeleteHandler = (id: string) => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        deleteRoomHandler(id, async () => {
          await Promise.all([getRoomHandler(), getDashboardStatsHandler()]);
        });
        return;
      case HomeTabEnum.GROUPS:
        deleteGroupHandler(id, async () => {
          await Promise.all([getGroupHandler(), getDashboardStatsHandler()]);
        });
        return;
      case HomeTabEnum.EXAMS:
        deleteExamHandler(id, async () => {
          await Promise.all([getExamHandler(), getDashboardStatsHandler()]);
        });
        return;
    }
  };
  const onAddHandler = () => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        navigate(PageLinks.class.create);
        return;
      case HomeTabEnum.GROUPS:
        navigate(PageLinks.studentGroup.create);
        return;
      case HomeTabEnum.EXAMS:
        navigate(PageLinks.exam.create);
        return;
    }
  };
  const onEditHandler = (id: string) => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        navigate(PageLinks.class.edit(id));
        return;
      case HomeTabEnum.GROUPS:
        navigate(PageLinks.studentGroup.edit(id));
        return;
      case HomeTabEnum.EXAMS:
        navigate(PageLinks.exam.edit(id));
        return;
    }
  };
  const onToggleStatusHandler = (id: string) => {
    switch (type) {
      case HomeTabEnum.ROOMS:
        classroomToggleStatusHandler(id, async () => {
          await Promise.all([getRoomHandler(), getDashboardStatsHandler()]);
        });
        return;
      case HomeTabEnum.GROUPS:
        studentGroupToggleStatusHandler(id, async () => {
          await Promise.all([getGroupHandler(), getDashboardStatsHandler()]);
        });
        return;
      case HomeTabEnum.EXAMS:
        examToggleStatusHandler(id, async () => {
          await Promise.all([getExamHandler(), getDashboardStatsHandler()]);
        });

        return;
    }
  };

  const filteredData = useMemo(() => {
    if (status === "all") {
      return data;
    }
    if (status === "active") {
      return data?.filter((e) => e?.active);
    }
    if (status === "inactive") {
      return data?.filter((e) => !e?.active);
    }
    return [];
  }, [data, status]);

  return (
    <div className={` ${className} overflow-scroll `}>
      {data?.length > 0 && (
        <div className={"flex items-center justify-center gap-5 my-2"}>
          {[
            { name: "all", label: "All" },
            { name: "active", label: "Active" },
            { name: "inactive", label: "Inactive" },
          ].map((e, key) => {
            const isActive = e?.name == status;
            return (
              <label
                key={key}
                className={
                  "flex items-center gap-2 cursor-pointer  sm:text-[12px]"
                }
              >
                <input
                  type={"radio"}
                  onChange={() => setStatus(e?.name)}
                  checked={isActive}
                />{" "}
                {e?.label}
              </label>
            );
          })}
        </div>
      )}
      {isLoading ? (
        <div className={"text-center mt-2"}>Please wait...</div>
      ) : data?.length < 1 ? (
        <div className={"flex items-center"}>
          <MyButton
            onClick={onAddHandler}
            isOutline
            colorType={"blue"}
            iconType={IconTypeEnum.ADD}
            name={`Add ${type == "ROOMS" ? "Room" : type == "GROUPS" ? "Group" : type == "EXAMS" ? "Exam" : ""}`}
          />
        </div>
      ) : filteredData.length < 1 ? (
        <div className={"text-center mt-2"}>No data</div>
      ) : (
        <div className={""}>
          {filteredData?.map((e, key) => {
            return (
              <div
                onClick={() => onClickHandler(e?.id)}
                key={key}
                className={`  flex items-center justify-between gap-2 text-[16px] sm:text-[12px] border-b p-2  cursor-pointer hover:bg-gray-50`}
              >
                <div className={"flex items-center gap-2"}>
                  <div
                    className={
                      "bg-blue-50 p-3 sm:p-2 rounded-full text-blue-600"
                    }
                  >
                    <Icon className={"text-[20px] sm:text-[16px]"} />
                  </div>
                  <span className={"font-bold"}>{e?.name}</span>
                </div>
                <div className={"flex items-center gap-2"}>
                  <div
                    onClick={(_e) => {
                      _e?.stopPropagation();
                      onToggleStatusHandler(e?.id);
                    }}
                    className={`flex items-center gap-2 text-[12px] px-3 py-1 cursor-pointer rounded-full `}
                  >
                    {e?.active ? "Active" : "Inactive"}
                    <MyToggleButton
                      value={e?.active}
                      changeHandler={() => {}}
                    />
                  </div>
                  <MyMoreOptionButton
                    options={[
                      type != HomeTabEnum.EXAMS && {
                        name: "Edit",
                        handler() {
                          onEditHandler(e?.id);
                        },
                      },
                      {
                        name: "Delete",
                        handler() {
                          onDeleteHandler(e?.id);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const TabComponent = ({ Menu, activeTab }) => {
  const [query, setQuery] = useSearchParams();
  return (
    <div
      className={
        "bg-gray-50 grid grid-cols-3 rounded-md p-1 text-[14px] mx-8 gap-2 shadow-sm  max-w-[500px] sm:max-w-[300px] w-full"
      }
    >
      {Menu?.map((e, key) => {
        const isActive =
          e?.key === activeTab || (e?.key === HomeTabEnum.EXAMS && !activeTab);
        return (
          <div
            onClick={() => {
              query.set(QueryStringEnum.TAB, e?.key);
              setQuery(query);
            }}
            className={`text-center cursor-pointer  py-1 text-[16px] sm:text-[12px] font-semibold ${isActive ? "bg-[#08B195] text-white rounded-md" : ""}`}
            key={key}
          >
            {e?.name}s
          </div>
        );
      })}
    </div>
  );
};
const CreateMenuComponent = ({ Menu }) => {
  const navigate = useNavigate();
  return (
    <div className={"grid grid-cols-3 gap-2 bg-gray-50 p-2 rounded-md "}>
      {Menu.map((e, key) => {
        const Icon = e?.icon;
        return (
          <div
            onClick={() => navigate(e?.path)}
            className={` hover:bg-gray-100 p-2 cursor-pointer flex flex-col justify-center gap-2 items-center text-center rounded-md`}
            key={key}
          >
            <div style={{}} className={"bg-white rounded-lg shadow-sm  p-3"}>
              <Icon
                style={{
                  color: e?.color,
                }}
                className={"text-[30px] sm:text-[20px]"}
              />
            </div>
            <span className={"text-[14px] sm:text-[12px] whitespace-nowrap"}>
              Create {e?.name}
            </span>
          </div>
        );
      })}
    </div>
  );
};
const StatsComponent = ({ Stats }) => {
  return (
    <div className={"bg-gray-50 text-black py-2 rounded-md "}>
      <div className={"grid grid-cols-3 divide-x"}>
        {Stats?.map((e, key) => {
          return (
            <div
              key={key}
              className={
                "flex flex-col items-center justify-center text-center text-[14px] sm:text-[12px] pl-4"
              }
            >
              <span className={"text-[20px] sm:text-[16px]"}>{e?.count}</span>
              <span className={"whitespace-nowrap"}>{e?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const EditNameComponent = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    state: { profileDetails },
    handlers: { editProfileHandler },
  } = useContext(SystemContext);
  const EditIcon = getIconsHandler(IconTypeEnum.EDIT);
  const onSubmitHandler = (values: any) => {
    editProfileHandler(values, () => {
      setModalOpen(false);
    });
  };
  return (
    <>
      <div className={"w-full flex items-center gap-2 "}>
        <span className={"font-semibold text-[22px] sm:text-[16px]"}>
          Hello {profileDetails?.name || ""}{" "}
        </span>
        <EditIcon
          onClick={() => setModalOpen(true)}
          className={"cursor-pointer"}
        />
      </div>
      {isModalOpen && (
        <BoxModal
          title={"Edit Profile"}
          closeHandler={() => setModalOpen(false)}
        >
          <Formik
            initialValues={{
              name: profileDetails?.name || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(" "),
            })}
            onSubmit={onSubmitHandler}
          >
            <Form className={"flex flex-col gap-2"}>
              <MyTextField
                isRequired
                name={"name"}
                label={"Name"}
                placeholder={"eg: Orchid English School"}
              />
              <FormSubmitButtonsComponent />
            </Form>
          </Formik>
        </BoxModal>
      )}
    </>
  );
};
export default HomePage;
