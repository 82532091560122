import { IconTypeEnum } from "interfaces";
import { getIconsHandler } from "helpers";

import { MyToggleButtonInterface } from "./interfaces";

export default function MyToggleButton({
  changeHandler,
  value,
  label,
}: MyToggleButtonInterface) {
  const ToggleOnIcon = getIconsHandler(IconTypeEnum.TOGGLE_ON);
  const ToggleOffIcon = getIconsHandler(IconTypeEnum.TOGGLE_OFF);

  const activeButton = <ToggleOnIcon className={"text-tGreen text-[20px]"} />;
  const inActiveButton = (
    <ToggleOffIcon className={" text-[#c0c0c0]  text-[20px]"} />
  );

  return (
    <div className={"cursor-pointer"}>
      {label && <span className={"whitespace-nowrap"}>{label} </span>}
      <div onClick={() => changeHandler(value)}>
        {value ? activeButton : inActiveButton}
      </div>
    </div>
  );
}
