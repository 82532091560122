import "./styles.css";

export default function Loader() {
  return (
    <div className="fixed z-[10000] flex items-center justify-center bg-white bg-opacity-50 w-full h-full top-0 left-0">
      <div className="flex gap-0 flex-col items-center">
        {/*<div className={"flex flex-col items-start text-[14px]"}>*/}
        {/*  <div className={"flex items-center text-[20px]"}>*/}
        {/*    <div className={"bg-black text-white px-1"}>Ex</div>*/}
        {/*    am*/}
        {/*  </div>*/}
        {/*  <div>Seat Planner</div>*/}
        {/*</div>*/}
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>Loading...</div>
      </div>
    </div>
  );
}
