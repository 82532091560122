import React, { useContext, useEffect, useState } from "react";
import { PageTemplate } from "templates";
import {
  AdminDashboardTabEnum,
  FeedbackInterface,
  LogsTypeEnum,
  QueryStringEnum,
  SchoolInterface,
} from "interfaces";
import { useSearchParams } from "react-router-dom";
import {
  DataContext,
  FeedbackContext,
  FileContext,
  SchoolContext,
} from "context";
import {
  MyButton,
  MyMoreOptionButton,
  MyToggleButton,
  TableComponent,
} from "components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import moment from "moment";

function AdminDashboardPage() {
  const [query, setQuery] = useSearchParams();
  const activeTab = query.get("tab");
  const { dashboardStats } = useContext(DataContext);

  const Menu = [
    {
      key: AdminDashboardTabEnum.SCHOOL,
      name: "Schools",
    },
    {
      key: AdminDashboardTabEnum.FEEDBACK,
      name: "Feedbacks",
    },
    {
      key: AdminDashboardTabEnum.LOGS,
      name: "Logs",
    },
  ];

  const Stats = [
    {
      name: "Verified Schools",
      count: dashboardStats?.verified_schools_count || 0,
    },
    {
      name: "UnVerified Schools",
      count:
        dashboardStats?.school_count - dashboardStats?.verified_schools_count ||
        0,
    },
    {
      name: "Active Exams",
      count: dashboardStats?.active_exam_count || 0,
    },
    {
      name: "Total Students",
      count: dashboardStats?.student_count || 0,
    },
    {
      name: "Total Groups",
      count: dashboardStats?.student_groups || 0,
    },
    {
      name: "Public School",
      count: dashboardStats?.public_school_count || 0,
    },
  ];

  const ActiveList = (() => {
    switch (activeTab) {
      case AdminDashboardTabEnum.SCHOOL:
        return <TabContentComponent type={AdminDashboardTabEnum.SCHOOL} />;
      case AdminDashboardTabEnum.FEEDBACK:
        return <TabContentComponent type={AdminDashboardTabEnum.FEEDBACK} />;
      case AdminDashboardTabEnum.LOGS:
        return <TabContentComponent type={AdminDashboardTabEnum.LOGS} />;
      default:
        return <TabContentComponent type={AdminDashboardTabEnum.SCHOOL} />;
    }
  })();

  return (
    <PageTemplate>
      <div className={"flex flex-col gap-5 w-full h-full  "}>
        <StatsComponent Stats={Stats} />
        <div className={"flex flex-col gap-2 items-center w-full "}>
          <TabComponent Menu={Menu} activeTab={activeTab} />
          <div className={"w-full h-full overflow-scroll"}>{ActiveList}</div>
        </div>
      </div>
    </PageTemplate>
  );
}

const TabContentComponent = ({ type }: { type?: AdminDashboardTabEnum }) => {
  const {
    lists: feedbacks,
    deleteHandler: deleteFeedbackHandler,
    getListsHandler: getFeedbacksHandler,
  } = useContext(FeedbackContext);
  const [selectedLogsType, setSelectedLog] = useState<any>(
    LogsTypeEnum.APPLICATION,
  );
  const {
    requestText,
    systemText,
    applicationText,
    getFileHandler,
    deleteFileHandler,
    isLoading: isFileLoading,
  } = useContext(FileContext);
  const {
    lists: adminSchools,
    listObject: schoolObject,
    toggleStatusHandler: toggleSchoolStatusHandler,
    getListsHandler: getAdminSchoolHandler,
  } = useContext(SchoolContext);
  useEffect(() => {
    if (type == AdminDashboardTabEnum.LOGS) {
      getFileHandler(selectedLogsType);
    }
  }, [selectedLogsType]);
  if (type === AdminDashboardTabEnum.SCHOOL) {
    return (
      <TableComponent
        column={[
          {
            title: "SN.",
            render(renderData, key) {
              return <span>{key + 1}.</span>;
            },
          },
          {
            title: "Name",
            render(renderData: SchoolInterface, key) {
              return (
                <span className={"whitespace-nowrap"}>
                  {renderData?.name || "-"}
                </span>
              );
            },
          },
          {
            title: "Email",
            render(renderData: SchoolInterface, key) {
              return (
                <span className={"whitespace-nowrap"}>
                  {renderData?.email || "-"}
                </span>
              );
            },
          },
          {
            title: "Email Status",
            render(renderData: SchoolInterface, key) {
              return (
                <span className={"whitespace-nowrap"}>
                  {renderData?.email_verified ? "Verified" : "Unverified"}
                </span>
              );
            },
          },
          {
            title: "Status",
            render(renderData: SchoolInterface, key) {
              return (
                <MyToggleButton
                  value={renderData?.active}
                  label={renderData?.active ? "Active" : "Disabled"}
                  changeHandler={() => {
                    toggleSchoolStatusHandler(renderData?.id, () => {
                      getAdminSchoolHandler();
                    });
                  }}
                />
              );
            },
          },
          {
            title: "Student lookup",
            render(renderData: SchoolInterface, key) {
              return <span>{renderData?.public ? "Public" : "Private"}</span>;
            },
          },
          {
            title: "Active Exams",
            render(renderData: SchoolInterface, key) {
              return <span>{renderData?.total_active_exams || "-"}</span>;
            },
          },
          {
            title: "Total Groups",
            render(renderData: SchoolInterface, key) {
              return <span>{renderData?.total_groups || "-"}</span>;
            },
          },
          {
            title: "Total Students",
            render(renderData: SchoolInterface, key) {
              return <span>{renderData?.total_students || "-"}</span>;
            },
          },
          {
            title: "Joined at",
            render(renderData: SchoolInterface, key) {
              return renderData?.created_at ? (
                <span>
                  {moment(renderData?.created_at).format("YYYY-MM-DD")}
                </span>
              ) : (
                <span>-</span>
              );
            },
          },
        ]}
        dataSource={adminSchools}
      />
    );
  } else if (type === AdminDashboardTabEnum.LOGS) {
    return (
      <div className={"flex flex-col gap-2"}>
        <div className={"flex items-center justify-between"}>
          <select
            value={selectedLogsType}
            onChange={(e) => setSelectedLog(e?.target.value)}
            className={"input_style"}
          >
            {[
              { label: "Application", value: LogsTypeEnum.APPLICATION },
              { label: "System", value: LogsTypeEnum.SYSTEM },
              { label: "Request", value: LogsTypeEnum.REQUEST },
            ].map((e, key) => {
              return (
                <option key={key} value={e?.value}>
                  {e?.label}
                </option>
              );
            })}
          </select>
          <div>
            <MyButton
              isOutline
              name={"Clear Logs"}
              colorType={"danger"}
              onClick={() => deleteFileHandler(selectedLogsType)}
            />
          </div>
        </div>
        {isFileLoading ? (
          <div className={"text-center"}>Loading...</div>
        ) : (
          <SyntaxHighlighter language="text" style={{ ...vscDarkPlus }}>
            {selectedLogsType == LogsTypeEnum.SYSTEM
              ? systemText
              : selectedLogsType == LogsTypeEnum.REQUEST
                ? requestText
                : applicationText}
          </SyntaxHighlighter>
        )}
      </div>
    );
  } else {
    return (
      <TableComponent
        column={[
          {
            title: "SN.",
            render(renderData, key) {
              return <span>{key + 1}.</span>;
            },
          },
          {
            title: "Name",
            render(renderData: FeedbackInterface, key) {
              return (
                <span>{schoolObject[renderData?.school_id]?.name || "-"}</span>
              );
            },
          },
          {
            title: "Subject",
            render(renderData: FeedbackInterface, key) {
              return <span>{renderData?.subject || "-"}</span>;
            },
          },
          {
            title: "Message",
            render(renderData: FeedbackInterface, key) {
              return <span>{renderData?.feedback || "-"}</span>;
            },
          },

          {
            title: "Created at",
            render(renderData: FeedbackInterface, key) {
              return (
                <span>
                  {renderData?.created_at
                    ? moment(renderData?.created_at).fromNow()
                    : "-"}
                </span>
              );
            },
          },
          {
            title: "",
            render(renderData: FeedbackInterface, key) {
              return (
                <MyMoreOptionButton
                  options={[
                    {
                      name: "Delete",
                      handler() {
                        deleteFeedbackHandler(renderData?.id, () =>
                          getFeedbacksHandler(),
                        );
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
        dataSource={feedbacks}
      />
    );
  }
};

const TabComponent = ({ Menu, activeTab }) => {
  const [query, setQuery] = useSearchParams();
  return (
    <div
      className={
        "bg-gray-50 grid grid-cols-3 rounded-md p-1 text-[14px] mx-8 gap-2 shadow-sm  "
      }
    >
      {Menu?.map((e, key) => {
        const isActive =
          e?.key === activeTab ||
          (e?.key === AdminDashboardTabEnum.SCHOOL && !activeTab);
        return (
          <div
            onClick={() => {
              query.set(QueryStringEnum.TAB, e?.key);
              setQuery(query);
            }}
            className={`text-center cursor-pointer min-w-[180px] sm:min-w-[100px]  py-1 text-[16px] sm:text-[12px] font-semibold ${isActive ? "bg-[#08B195] text-white rounded-md" : ""}`}
            key={key}
          >
            {e?.name}
          </div>
        );
      })}
    </div>
  );
};

const StatsComponent = ({ Stats }) => {
  return (
    <div className={"bg-gray-50 text-black py-2 rounded-md "}>
      <div
        className={
          "grid grid-cols-3 sm:grid-cols-2 divide-x sm:divide-x-0 sm:gap-2"
        }
      >
        {Stats?.map((e, key) => {
          return (
            <div
              key={key}
              className={
                "flex flex-col items-center justify-center text-center text-[14px] sm:text-[12px] pl-4"
              }
            >
              <span className={"text-[20px] sm:text-[16px]"}>{e?.count}</span>
              <span className={"whitespace-nowrap"}>{e?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AdminDashboardPage;
