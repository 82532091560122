import React, { useContext } from "react";

import * as yup from "yup";

import { DataContext } from "context";
import { Form, Formik } from "formik";

import {
  MyButton,
  MySelectField,
  MyTextField,
  TableComponent,
} from "components";
import { getSeatFormatHandler } from "helpers";
import { ExamLayoutInterface } from "interfaces";
import { PageLinks } from "routes";
import { useNavigate } from "react-router-dom";
import { VideoListModel } from "../content";
import { useApplicationHook } from "../useHooks";

function FindPositionPage() {
  const {
    schools,
    seatDetails,
    handlers: { getSeatDetailsHandler },
  } = useContext(DataContext);
  const navigate = useNavigate();

  const onSubmitHandler = (values: any) => {
    getSeatDetailsHandler(values);
  };
  const { fullHeight } = useApplicationHook();
  return (
    <div
      style={{ height: fullHeight }}
      className={"h-full w-full overflow-x-scroll hideScroll"}
    >
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{ school_id: "", symbol_no: "" }}
        validationSchema={yup.object().shape({
          symbol_no: yup.string().required(" "),
          school_id: yup.string().required(" "),
        })}
      >
        <Form
          className={
            " flex flex-col h-full w-full  p-4 justify-between items-start overflow-x-scroll hideScroll"
          }
        >
          <div
            className={
              "flex flex-col gap-20 w-full items-center  overflow-x-scroll hideScroll"
            }
          >
            <div className={"w-full flex items-center justify-between"}>
              <a href={"http://examseatplanner.com"} target={"_blank"}>
                <img
                  src={"/img/logo/logo.svg"}
                  className={"h-[60px] sm:h-[40px] object-contain"}
                  alt={"icon"}
                />
              </a>
              <div className={"flex items-center gap-4"}>
                <VideoListModel />
                <div
                  className={
                    "cursor-pointer px-3 py-1 rounded-md hover:underline"
                  }
                  onClick={() => navigate(PageLinks.login)}
                >
                  Login
                </div>
              </div>
            </div>
            <div
              className={
                "flex flex-col items-center gap-10  w-[800px] sm:w-full  overflow-x-scroll hideScroll"
              }
            >
              <div
                className={"text-center  text-[20px] uppercase font-bold mb-3"}
              >
                Exam Details
              </div>
              <div className={"flex flex-col gap-2 w-[400px] sm:w-full"}>
                <MySelectField
                  isRequired
                  option={{
                    selectOptions: schools?.map((e) => ({
                      value: e?.id,
                      label: e?.name,
                    })),
                  }}
                  label={"School"}
                  name={"school_id"}
                />
                <MyTextField
                  isRequired
                  placeholder={"Enter symbol number"}
                  name={"symbol_no"}
                  label={"Symbol number"}
                />
                <MyButton name={"Find"} type={"submit"} />
              </div>

              {seatDetails?.length > 0 ? (
                <div className={"overflow-x-scroll w-full"}>
                  <TableComponent
                    column={[
                      {
                        title: "SN",
                        render(renderData: any, key: number) {
                          return <span>{key + 1}.</span>;
                        },
                      },
                      {
                        title: "Student Name",
                        render(renderData: ExamLayoutInterface) {
                          return (
                            <span>
                              {renderData?.student_name} [
                              {renderData?.group_name || ""}]
                            </span>
                          );
                        },
                      },
                      {
                        title: "Exam Name",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.exam_name}</span>;
                        },
                      },
                      {
                        title: "Exam Hall",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.class_name}</span>;
                        },
                      },
                      {
                        title: "Column",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.column_name}</span>;
                        },
                      },
                      {
                        title: "Row",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.row_position}</span>;
                        },
                      },
                      {
                        title: "Bench Position",
                        render(renderData: ExamLayoutInterface) {
                          return <span>{renderData?.position}</span>;
                        },
                      },
                    ]}
                    dataSource={seatDetails}
                  />
                </div>
              ) : (
                <div className={"text-center text-[14px]"}>
                  No details found
                </div>
              )}
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default FindPositionPage;
