import { LS_NAME } from "interfaces";

export const getAuthToken = (type: LS_NAME) => {
  return localStorage.getItem(type);
};

export const clearAuthToken = (type: LS_NAME) => {
  localStorage.removeItem(type);
};

export const setAuthToken = (token: string, type: LS_NAME) => {
  localStorage.setItem(type, token);
};
