import React, { useContext } from "react";
import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Formik } from "formik";
import { FormSubmitButtonsComponent, MyButton } from "components";
import * as yup from "yup";
import { StudentGroupContext } from "context";
import { HomeTabEnum, IconTypeEnum, ParamsStringEnum } from "interfaces";
import { PageLinks } from "routes";
import { downloadFileHandler } from "helpers";

function UploadStudentExcelPage() {
  const { group_id } = useParams<ParamsStringEnum>();

  const navigate = useNavigate();
  const { uploadStudentExcelHandler, getListsHandler } =
    useContext(StudentGroupContext);

  const submitHandler = (values: any) => {
    const formData = new FormData();
    formData.append("file", values?.file);
    uploadStudentExcelHandler({ formData, group_id }, () => {
      getListsHandler();

      navigate(PageLinks.studentGroup.details(group_id));
    });
  };

  const onDownloadFile = () => {
    downloadFileHandler(
      "/student_group_template.xlsx",
      "student_group_template.xlsx",
    );
  };

  return (
    <PageTemplate
      backPath={PageLinks.studentGroup.details(group_id)}
      title={"Upload Students"}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={FormValues.initialValues()}
        validationSchema={FormValues.validationSchema}
      >
        {({ setFieldValue }) => {
          return (
            <Form className={"flex flex-col gap-5 mb-20"}>
              <div className={"flex flex-col gap-2 w-full"}>
                <div className={"flex items-end justify-end"}>
                  <div>
                    <MyButton
                      onClick={onDownloadFile}
                      iconType={IconTypeEnum.EXCEL}
                      isOutline
                      colorType={"blue"}
                      name={"Download Template File"}
                    />
                  </div>
                </div>

                <div className={"flex flex-col gap-2"}>
                  <span className={"font-bold"}>
                    Upload Excel File <span className={"text-red-500"}>*</span>
                  </span>{" "}
                  <input
                    accept={".xlsx"}
                    type={"file"}
                    name={"file"}
                    onChange={(e) => {
                      const file = e?.target?.files[0];
                      setFieldValue("file", file);
                    }}
                  />
                </div>
              </div>
              <FormSubmitButtonsComponent />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

const FormValues = {
  initialValues: () => {
    return {
      file: "",
    };
  },
  validationSchema: yup.object().shape({
    file: yup.string().required(" "),
  }),
};
export default UploadStudentExcelPage;
