import React, { createContext } from "react";
import { ClassRoomInterface, DataContextInterface } from "interfaces";
import { useDataContextHook } from "useHooks";
import { ApiUrl } from "services";

export const ClassRoomContext = createContext<
  DataContextInterface<ClassRoomInterface>
>({
  details: undefined,
  isDetailsLoading: false,
  isLoading: false,
  lists: [],
  listObject: undefined,
  deleteHandler(
    item_id: string,
    onSuccessHandler?: (payload: any) => {},
  ): any {},
  editHandler(payload: any, onSuccessHandler?: (payload: any) => any): any {},
  getDetailsHandler(item_id: string): any {},
  getListsHandler(query?: any): any {},
  toggleStatusHandler(
    payload,
    onSuccessHandler?: (payload: any) => any,
  ): any {},
});
function ClassRoomContextProvider({ children }) {
  const {
    getListsHandler,
    getDetailsHandler,
    deleteHandler,
    editHandler,
    createHandler,
    isDetailsLoading,
    lists,
    isLoading,
    details,
    listObject,
    toggleStatusHandler,
  } = useDataContextHook();
  const handlers = {
    getDetailsHandler: (item_id: string) => {
      return getDetailsHandler(ApiUrl.class.get_details(item_id));
    },
    deleteHandler(
      item_id: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return deleteHandler(ApiUrl.class.delete(item_id), onSuccessHandler);
    },
    editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {
      return payload?.id
        ? editHandler(
            ApiUrl.class.put_edit(payload?.id),
            payload,
            onSuccessHandler,
          )
        : createHandler(ApiUrl.class.post_create, payload, onSuccessHandler);
    },
    getListsHandler(query?: any): any {
      return getListsHandler(ApiUrl.class.get_lists, query);
    },
    toggleStatusHandler(
      itemId: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return toggleStatusHandler(
        ApiUrl.class.put_toggleStatus(itemId),
        {},
        onSuccessHandler,
      );
    },
  };
  return (
    <ClassRoomContext.Provider
      value={{
        isLoading,
        isDetailsLoading,
        lists,
        details,
        listObject,
        ...handlers,
      }}
    >
      {children}
    </ClassRoomContext.Provider>
  );
}

export default ClassRoomContextProvider;
