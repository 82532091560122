import React, { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { DataContext, StudentGroupContext } from "context";
import { MyButton, MyMoreOptionButton, TableComponent } from "components";
import {
  ClassRoomInterface,
  HomeTabEnum,
  IconTypeEnum,
  StudentGroupInterface,
} from "interfaces";

function StudentGroupPage() {
  const { lists, getListsHandler, deleteHandler } =
    useContext(StudentGroupContext);
  const navigate = useNavigate();

  return (
    <PageTemplate
      title={"Student Group"}
      rightChildren={
        <MyButton
          onClick={() => navigate(PageLinks.studentGroup.create)}
          isOutline
          name={"Create"}
          iconType={IconTypeEnum.ADD}
        />
      }
      backPath={PageLinks.home(HomeTabEnum.GROUPS)}
    >
      <TableComponent
        onRowClickHandler={(data: StudentGroupInterface) => {
          navigate(PageLinks.studentGroup.details(data.id));
        }}
        column={[
          {
            title: "SN.",
            render(renderData: ClassRoomInterface, key: number) {
              return <span>{key + 1}.</span>;
            },
          },
          {
            title: "Name",
            render(renderData: ClassRoomInterface) {
              return <span>{renderData?.name}</span>;
            },
          },

          {
            title: "",
            render(renderData: ClassRoomInterface) {
              return (
                <MyMoreOptionButton
                  options={[
                    {
                      name: "Edit",
                      handler() {
                        navigate(PageLinks.studentGroup.edit(renderData?.id));
                      },
                    },
                    {
                      name: "Delete",
                      handler() {
                        deleteHandler(renderData?.id, () => {
                          getListsHandler();
                        });
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
        dataSource={lists || []}
      />
    </PageTemplate>
  );
}

export default StudentGroupPage;
