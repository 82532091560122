import React from "react";
import Popup from "reactjs-popup";

import { MyMoreOptionsButtonInterface } from "./interfaces";
import { IoIosMore } from "react-icons/io";

export default function MoreOptionsButton({
  options,
  width,
}: MyMoreOptionsButtonInterface) {
  return (
    <Popup
      position={["bottom right", "bottom left"]}
      closeOnDocumentClick
      contentStyle={{ width: width || "200px" }}
      disabled={options?.length < 1}
      trigger={
        <button className="button" data-testid="more-options-button">
          <div
            className={
              "rounded-full p-1 text-gray-500 cursor-pointer hover:bg-white"
            }
          >
            <IoIosMore fontSize={18} />
          </div>
        </button>
      }
    >
      <div className={"flex flex-col "}>
        {options?.map((e, key) => {
          const isLastItem = options?.length - 1 === key;
          if (!e) {
            return;
          }
          return (
            <div
              className={`flex items-center gap-2 ${
                !isLastItem && "border-b-2"
              } px-2 py-2 text-black text-[14px] cursor-pointer hover:bg-gray-100`}
              key={key}
              onClick={() => e.handler()}
            >
              <span>{e.name}</span>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
