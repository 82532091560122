import React, { useState } from "react";
import { useField } from "formik";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./styles.css";
import { MyTextFieldInterface } from "./interfaces";

export default function MyPasswordField(props: MyTextFieldInterface) {
  const [field, meta] = useField(props);
  const [isToggle, setToggle] = useState(false);
  let isError = meta.touched && meta.error;
  const toggleHandler = () => {
    setToggle((e) => !e);
  };
  return (
    <div className="input_container">
      <label className={`input_label `}>
        {props?.label}
        {props?.isRequired && <span className={"text-tRed"}>*</span>}
      </label>{" "}
      <div
        className={`input_style  min-h-[42px]  border-[1px] px-3 rounded-md flex justify-between items-center gap-3  outline-none  focus:border-[#2680EB] ${
          isError ? "border-tRed" : "border-[#C0C0C0]"
        } `}
      >
        <input
          style={{
            border: "none",
            height: "35px",
            padding: 0,
          }}
          className="flex-1 border-transparent focus:border-transparent focus:ring-0"
          type={isToggle ? "text" : "password"}
          {...field}
          {...props}
        />
        <div
          className=" cursor-pointer flex min-w-[20px] items-center justify-center"
          onClick={toggleHandler}
        >
          {isToggle ? <AiFillEye /> : <AiOutlineEyeInvisible />}
        </div>
      </div>
      {isError && meta?.error?.trim() && (
        <span className="input_textError">* {meta.error}</span>
      )}
    </div>
  );
}
