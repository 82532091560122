import React, { createContext } from "react";
import { DataContextInterface, StudentGroupInterface } from "interfaces";
import { useDataContextHook } from "useHooks";
import { ApiUrl } from "services";
import { string } from "yup";

interface ContextInterface extends DataContextInterface<StudentGroupInterface> {
  editGroupStudentHandler(payload: any, onSuccessHandler?: () => any): any;
  deleteStudentHandler(payload: any, onSuccessHandler?: () => any): any;
  uploadStudentExcelHandler(payload: any, onSuccessHandler?: () => any): any;
}

export const StudentGroupContext = createContext<ContextInterface>({
  details: undefined,
  isDetailsLoading: false,
  isLoading: false,
  lists: [],
  listObject: undefined,
  deleteHandler(
    item_id: string,
    onSuccessHandler?: (payload: any) => {},
  ): any {},
  editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {},
  getDetailsHandler(item_id: string): any {},
  getListsHandler(query?: any): any {},
  editGroupStudentHandler(payload, onSuccessHandler) {},
  uploadStudentExcelHandler(payload, onSuccessHandler) {},
  toggleStatusHandler(
    itemId: string,
    onSuccessHandler?: (payload: any) => any,
  ): any {},
  deleteStudentHandler(payload, onSuccessHandler) {},
});
function StudentGroupContextProvider({ children }) {
  const {
    getListsHandler,
    getDetailsHandler,
    deleteHandler,
    editHandler,
    createHandler,
    isDetailsLoading,
    lists,
    isLoading,
    details,
    listObject,
    toggleStatusHandler,
  } = useDataContextHook();
  const handlers = {
    getDetailsHandler: (item_id: string) => {
      return getDetailsHandler(ApiUrl.studentGroup.get_details(item_id));
    },
    deleteHandler(
      item_id: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return deleteHandler(
        ApiUrl.studentGroup.delete(item_id),
        onSuccessHandler,
      );
    },
    editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {
      return payload?.id
        ? editHandler(ApiUrl.studentGroup.put_edit, payload, onSuccessHandler)
        : createHandler(
            ApiUrl.studentGroup.post_create,
            payload,
            onSuccessHandler,
          );
    },
    getListsHandler(query?: any): any {
      return getListsHandler(ApiUrl.studentGroup.get_lists, query);
    },
    editGroupStudentHandler(payload: any, onSuccessHandler?: () => any): any {
      return createHandler(
        ApiUrl.student.post_create,
        payload,
        onSuccessHandler,
      );
    },
    uploadStudentExcelHandler(payload: any, onSuccessHandler?: () => any): any {
      return createHandler(
        ApiUrl.student.post_uploadExel(payload?.group_id),
        payload.formData,
        onSuccessHandler,
        true,
      );
    },
    toggleStatusHandler(
      itemId: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return toggleStatusHandler(
        ApiUrl.studentGroup.put_toggleStatus(itemId),
        {},
        onSuccessHandler,
      );
    },
    deleteStudentHandler(
      payload: any,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      return editHandler(
        ApiUrl.student.delete_multiple,
        payload,
        onSuccessHandler,
        "Deleted Successfully",
      );
    },
  };
  return (
    <StudentGroupContext.Provider
      value={{
        isLoading,
        isDetailsLoading,
        lists,
        listObject,
        details,
        ...handlers,
      }}
    >
      {children}
    </StudentGroupContext.Provider>
  );
}

export default StudentGroupContextProvider;
