import React, { useContext, useEffect, useState } from "react";
import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Formik, useFormikContext } from "formik";
import {
  BoxModal,
  FormSubmitButtonsComponent,
  MyButton,
  MyTextField,
} from "components";
import * as yup from "yup";
import { RoomLayoutContent } from "content";
import { ClassRoomContext, DataContext } from "context";
import {
  ClassRoomInterface,
  ClassRoomLayoutInterface,
  HomeTabEnum,
  ParamsStringEnum,
} from "interfaces";
import { PageLinks } from "routes";

const MAX_SEAT_CAPACITY = 7;
const MAX_CLASSROOM_ROW = 50;
const MAX_CLASSROOM_COLUMN = 10;

function EditClassPage() {
  const { class_id } = useParams<ParamsStringEnum>();
  const [benchPosition, setBenchPosition] = useState<{
    row: number;
    column: number;
  }>(undefined);
  const navigate = useNavigate();
  const {
    details,
    isDetailsLoading,
    editHandler,
    getDetailsHandler,
    getListsHandler,
  } = useContext(ClassRoomContext);
  const {
    handlers: { getDashboardStatsHandler },
  } = useContext(DataContext);

  const submitHandler = (values: any) => {
    editHandler(values, async () => {
      await Promise.all([getListsHandler(), getDashboardStatsHandler()]);
      navigate(PageLinks.home(HomeTabEnum.ROOMS));
    });
  };
  useEffect(() => {
    if (class_id) {
      getDetailsHandler(class_id);
    }
  }, [class_id]);
  return (
    <PageTemplate
      isLoading={class_id && isDetailsLoading}
      backPath={PageLinks.home(HomeTabEnum.ROOMS)}
      title={class_id ? `Edit Room` : "Create Room"}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={FormValues.initialValues(
          class_id && {
            id: class_id,
            ...details,
          },
        )}
        validationSchema={FormValues.validationSchema}
      >
        {({ values }) => {
          return (
            <Form className={"flex flex-col gap-8 mb-20"}>
              <FormListenerComponent isEdit={class_id} />
              <div className={"flex flex-col gap-5"}>
                <MyTextField
                  name={"name"}
                  label={"Name"}
                  placeholder={"eg: L1"}
                  isRequired
                />
                <div className={"grid grid-cols-2 gap-2 items-start"}>
                  <MyTextField
                    name={"column_count"}
                    label={"Column count"}
                    type={"number"}
                    placeholder={"eg: 2"}
                    isRequired
                  />{" "}
                  <MyTextField
                    name={"row_count"}
                    label={"Row count"}
                    type={"number"}
                    placeholder={"eg: 2"}
                    isRequired
                  />{" "}
                </div>
                <MyTextField
                  name={"default_capacity"}
                  label={"Default Capacity"}
                  type={"number"}
                  placeholder={"eg: 2"}
                />
              </div>
              {values?.class_room_layout?.length > 0 &&
              values?.row_count &&
              values?.column_count ? (
                <RoomLayoutContent
                  classRoomLayout={values?.class_room_layout}
                  column_count={values?.column_count}
                  row_count={values?.row_count}
                  onClickBench={(columnPosition, rowPosition) => {
                    setBenchPosition({
                      row: rowPosition,
                      column: columnPosition,
                    });
                  }}
                />
              ) : (
                <></>
              )}
              {benchPosition && (
                <SeatEditModel
                  columnPosition={benchPosition.column}
                  rowPosition={benchPosition.row}
                  closeHandler={() => setBenchPosition(undefined)}
                />
              )}
              <FormSubmitButtonsComponent />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

const SeatEditModel = ({
  closeHandler,
  columnPosition,
  rowPosition,
}: {
  closeHandler?(): any;
  columnPosition: number;
  rowPosition: number;
}) => {
  const { values, setFieldValue } = useFormikContext<ClassRoomInterface>();
  const [capacity, setCapacity] = useState<number>(0);
  useEffect(() => {
    let defaultSeatValue = values?.class_room_layout?.find(
      (e) =>
        e?.row_position == rowPosition && e?.column_position == columnPosition,
    );
    setCapacity(defaultSeatValue?.capacity || 0);
  }, []);

  const onSaveHandler = () => {
    let findBenchCurrentIndex = values?.class_room_layout?.findIndex(
      (e) =>
        e?.row_position == rowPosition && e?.column_position == columnPosition,
    );
    if (
      findBenchCurrentIndex > -1 &&
      (capacity < MAX_SEAT_CAPACITY || !capacity)
    ) {
      setFieldValue(
        `class_room_layout.${findBenchCurrentIndex}.capacity`,
        capacity || 0,
        false,
      );
    }
    closeHandler();
  };
  return (
    <BoxModal title={"Seat Capacity"} closeHandler={() => closeHandler()}>
      <div className={"flex flex-col gap-2"}>
        <input
          className={"input_style"}
          value={capacity}
          onChange={(e) => setCapacity(parseInt(e?.target?.value))}
          name={"capacity"}
          placeholder={"eg: 2"}
          type={"number"}
        />
        <MyButton name={"Save"} onClick={onSaveHandler} />
        <div className={"text-[14px] text-center bg-red-50 py-1"}>
          Enter `0 or none` to remove bench
        </div>
      </div>
    </BoxModal>
  );
};
const FormListenerComponent = ({ isEdit }) => {
  const { values, setFieldValue } = useFormikContext<ClassRoomInterface>();

  const generateLayoutHandler = (
    rowCount: number,
    columnCount: number,
    capacity: number,
  ) => {
    let tempLayouts = [];

    for (let i = 0; i < columnCount; i++) {
      for (let j = 0; j < rowCount; j++) {
        const rowPosition = j + 1;
        const columnPosition = i + 1;

        const found = values?.class_room_layout?.find(
          (e) =>
            e?.row_position == rowPosition &&
            e?.column_position == columnPosition,
        );

        tempLayouts.push(
          FormValues.classRoomLayoutValues({
            capacity:
              typeof found === "undefined" || !isEdit
                ? capacity
                : found?.capacity,
            row_position: rowPosition,
            column_position: columnPosition,
            column_name: String.fromCharCode(97 + i)?.toUpperCase(),
          }),
        );
      }
    }
    return tempLayouts;
  };

  useEffect(() => {
    if (
      values?.row_count &&
      values?.row_count < MAX_CLASSROOM_ROW &&
      values?.column_count &&
      values?.column_count < MAX_CLASSROOM_COLUMN &&
      values?.default_capacity
    ) {
      let layouts = generateLayoutHandler(
        values?.row_count,
        values?.column_count,
        values?.default_capacity,
      );
      setFieldValue("class_room_layout", layouts);
    }
  }, [
    values?.row_count,
    values?.column_count,
    values?.default_capacity,
    values?.id,
  ]);
  return <></>;
};

const FormValues = {
  classRoomLayoutValues: (values?: Partial<ClassRoomLayoutInterface>) => {
    return {
      column_position: values?.column_position,
      row_position: values?.row_position,
      capacity: values?.capacity,
      column_name: values?.column_name || "",
    };
  },
  initialValues: (values?: Partial<ClassRoomInterface>) => {
    return {
      id: values?.id,
      name: values?.name || "",
      column_count: values?.column_count || "",
      row_count: values?.row_count || "",
      default_capacity: 2,
      class_room_layout: values?.class_room_layout || [],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().typeError("invalid").required("required"),
    column_count: yup
      .number()
      .typeError("invalid")
      .max(10, "Invalid input")
      .required("required"),
    row_count: yup
      .number()
      .typeError("invalid")
      .max(50, "Invalid")
      .required("required"),
  }),
};
export default EditClassPage;
