import { createContext, useEffect, useReducer } from "react";

import {
  appReducer,
  appState,
  setAuthLoadingAction,
  setErrorAction,
  setLoadingAction,
  setLogoutAction,
  setPageErrorAction,
  setProfileAction,
  setSuccessAction,
} from "stores";

import { toast, ToastContainer } from "react-toastify";
import { Api, clearAuthToken, getAuthToken, setAuthToken } from "helpers";
import { LS_NAME, SchoolInterface } from "interfaces";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { ApiUrl } from "services";
import { type } from "@testing-library/user-event/dist/type";

export const SystemContext = createContext({
  state: { ...appState },
  handlers: {
    loginHandler: (payload: any) => {},
    signupHandler: (payload: any, onSuccessHandler: any) => {},
    logoutHandler: (askConfirm?: boolean) => {},
    setAuthLoadingHandler: (status: boolean) => {},
    setProfileHandler: (profileDetails: SchoolInterface) => {},
    setErrorHandler: (status: boolean, message: string) => {},
    setLoadingHandler: (status: boolean) => {},
    setPageErrorHandler: (status: boolean, message: string) => {},
    setSuccessHandler: (status: boolean, message: string) => {},
    setLoginModalHandler: (status: boolean) => {},
    getCurrentProfileHandler: () => {},
    editProfileHandler: (value: any, onSuccessHandler: any) => {},
  },
});

const SystemContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, appState);
  const navigate = useNavigate();
  const { postApi, getApi, putApi } = Api();
  const getCurrentProfileHandler = async () => {
    try {
      setAuthLoadingHandler(true);
      const res: any = await getApi(ApiUrl.auth.get_profile);
      const schoolDetails: SchoolInterface = res?.data;
      setProfileHandler(res?.data);
      if (!schoolDetails?.email_verified) {
        navigate(PageLinks.emailVerification);
      }
    } catch (err) {
      logoutHandler();
    } finally {
      setAuthLoadingHandler(false);
    }
  };

  useEffect(() => {
    if (getAuthToken(LS_NAME.accessToken)) {
      getCurrentProfileHandler();
    } else {
      setAuthLoadingHandler(false);
    }
  }, []);

  const loginHandler = async (payload: any) => {
    try {
      setLoadingHandler(true);
      const res = await postApi(ApiUrl.auth.post_login, payload);
      setAuthToken(res?.data, LS_NAME.accessToken);
      await getCurrentProfileHandler();
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoadingHandler(false);
    }
  };
  const signupHandler = async (payload: any, onSuccessHandler) => {
    try {
      setLoadingHandler(true);
      const res = await postApi(ApiUrl.auth.post_signup, payload);
      setAuthToken(res?.data, LS_NAME.accessToken);
      await getCurrentProfileHandler();

      if (typeof onSuccessHandler === "function") {
        onSuccessHandler();
      }
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoadingHandler(false);
    }
  };
  const editProfileHandler = async (values: any, onSuccessHandler: any) => {
    try {
      setLoadingHandler(true);
      await putApi(ApiUrl.auth.put_profile, values);
      setProfileHandler({
        ...state.profileDetails,
        name: values?.name,
      });
      if (typeof onSuccessHandler === "function") {
        onSuccessHandler();
      }
    } catch (err) {
    } finally {
      setLoadingHandler(false);
    }
  };

  const setProfileHandler = (profile: SchoolInterface) => {
    dispatch(setProfileAction(profile));
  };
  const logoutHandler = (askConfirm?: boolean) => {
    if (askConfirm) {
      if (!window.confirm("Are you sure?")) {
        return;
      }
    }
    dispatch(setLogoutAction());
    clearAuthToken(LS_NAME.accessToken);
    navigate(PageLinks.login);
  };
  const setLoginModalHandler = (status: boolean) => {};
  const setAuthLoadingHandler = (status: boolean) => {
    dispatch(setAuthLoadingAction(status));
  };
  const setErrorHandler = (status: boolean, message: string) => {
    dispatch(setErrorAction(status, message));
  };
  const setLoadingHandler = (status: boolean) => {
    dispatch(setLoadingAction(status));
  };
  const setPageErrorHandler = (status: boolean, message: string) => {
    dispatch(setPageErrorAction(status, message));
  };
  const setSuccessHandler = (status: boolean, message: string) => {
    dispatch(setSuccessAction(status, message));
  };

  useEffect(() => {
    if (state?.isActionSuccess) {
      toast.success(state?.successMessage, {
        onClose: () => setSuccessHandler(false, ""),
      });
    }
    if (state?.isError) {
      toast.error(state?.errorMessage, {
        onClose: () => setErrorHandler(false, ""),
      });
    }
  }, [state?.isError, state.isActionSuccess]);

  let contextValue = {
    state,
    handlers: {
      loginHandler,
      logoutHandler,
      setAuthLoadingHandler,
      setErrorHandler,
      setLoadingHandler,
      setPageErrorHandler,
      setSuccessHandler,
      setLoginModalHandler,
      setProfileHandler,
      signupHandler,
      getCurrentProfileHandler,
      editProfileHandler,
    },
  };
  return (
    <SystemContext.Provider value={contextValue}>
      {children}
      {state.isLoading && <Loader />}
      <ToastContainer limit={2} autoClose={5000} position={"bottom-right"} />
    </SystemContext.Provider>
  );
};

export default SystemContextProvider;
