import React, { useContext, useState } from "react";
import { getIconsHandler } from "helpers";
import { IconTypeEnum } from "interfaces";
import { Form, Formik } from "formik";
import {
  BoxModal,
  FormSubmitButtonsComponent,
  MyTextAreaField,
  MyTextField,
} from "components";
import * as yup from "yup";
import { DataContext } from "context";

function FeedbackModel() {
  const [isOpen, setOpen] = useState(false);
  const FeedbackIcon = getIconsHandler(IconTypeEnum.FEEDBACK);
  const {
    handlers: { sendFeedbackHandler },
  } = useContext(DataContext);
  const submitHandler = (values: any) => {
    sendFeedbackHandler(values, async () => {
      setOpen(false);
    });
  };
  return (
    <>
      <div
        className={"flex items-center  gap-1 cursor-pointer text-[14px]"}
        onClick={() => setOpen(true)}
      >
        <FeedbackIcon />
        <span>Feedback</span>
      </div>
      {isOpen && (
        <BoxModal title={"Feedback"} closeHandler={() => setOpen(false)}>
          <Formik
            enableReinitialize
            onSubmit={submitHandler}
            initialValues={FormValues.initialValues()}
            validationSchema={FormValues.validationSchema}
          >
            <Form className={"flex flex-col gap-5 px-2"}>
              <div className={"flex flex-col gap-2 p"}>
                <MyTextField
                  name={"subject"}
                  label={"Subject"}
                  placeholder={"Enter subject"}
                  isRequired
                />{" "}
                <MyTextAreaField
                  name={"feedback"}
                  label={"Message"}
                  placeholder={"Enter message"}
                  isRequired
                />
              </div>
              <FormSubmitButtonsComponent
                cancelButton={{
                  handler() {
                    setOpen(false);
                  },
                }}
              />
            </Form>
          </Formik>
        </BoxModal>
      )}
    </>
  );
}

const FormValues = {
  initialValues: (values?: Partial<any>) => {
    return {
      subject: "",
      feedback: "",
    };
  },
  validationSchema: yup.object().shape({
    subject: yup.string().typeError("invalid").required("required"),
    feedback: yup.string().typeError("invalid").required("required"),
  }),
};

export default FeedbackModel;
