import {
  HomeTabEnum,
  QueryStringEnum,
  ResetPasswordParamsEnum,
} from "../interfaces";

const PageLinks = {
  login: "/",
  emailVerification: "/email-verification",
  signUp: "/signup",
  home: (activeTab?: HomeTabEnum) =>
    `/home?${QueryStringEnum.TAB}=${activeTab || ""}`,
  position: "/find-position",
  pageBlocked: "/page-blocked",
  adminDashboard: "/admin/dashboard",
  class: {
    list: "/school/class",
    details: (id: string) => `/school/class/details/${id}`,
    edit: (id: string) => `/school/class/edit/${id}`,
    create: `/school/class/create`,
  },
  exam: {
    list: "/school/exam",
    details: (id: string) => `/school/exam/details/${id}`,
    edit: (id: string) => `/school/exam/edit/${id}`,
    create: `/school/exam/create`,
  },

  studentGroup: {
    list: "/student-group",
    details: (id: string) => `/student-group/details/${id}`,
    edit: (id: string) => `/student-group/edit/${id}`,
    create: `/student-group/create`,
  },
  students: {
    create: (group_id: string) => `/student-group/add-students/${group_id}`,
    uploadExcel: (group_id: string) =>
      `/student-group/update-excel/${group_id}`,
  },
  resetPassword: {
    request: "/reset-password/request",
    custom_message: (value: ResetPasswordParamsEnum) =>
      `/reset-password/${value}`,
    setPassword: "/reset-password/set-password",
  },
};

export default PageLinks;
