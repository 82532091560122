import { useContext } from "react";
import { SystemContext } from "../context";
import { MyButton } from "../components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "../routes";
import { useApplicationHook } from "../useHooks";

export default function Page404() {
  const {
    state: { isAuthenticated },
  } = useContext(SystemContext);
  const navigate = useNavigate();
  const { fullHeight } = useApplicationHook();
  return (
    <div
      style={{ height: fullHeight }}
      className="flex h-[100vh] gap-[40px] items-center justify-center"
    >
      <div
        className="gap-3 flex flex-col  items-center
      "
      >
        <h1 className="font-bold text-xl">Ops! Page Not Found</h1>
        <p>This Page doesn't exist or you don't have access.</p>
        <MyButton
          isOutline
          colorType={"blue"}
          name={"Go Home"}
          onClick={() =>
            isAuthenticated
              ? navigate(PageLinks.home())
              : navigate(PageLinks.login)
          }
        />
      </div>
    </div>
  );
}
