import { SchoolInterface } from "../../interfaces";

export interface AppReducerInterface {
  isLoading: boolean;
  isError: boolean;
  isActionSuccess: boolean;
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  havePageError: boolean;
  pageErrorMessage: string;
  profileDetails: SchoolInterface;
  errorMessage: string;
  successMessage: string;
  appSettings: any;
}
export enum ReducerActionTypeInterface {
  APP_SET_ERROR = "APP_SET_ERROR",
  APP_SET_SUCCESS = "APP_SET_SUCCESS",
  APP_SET_LOADING = "APP_SET_LOADING",
  APP_SET_AUTH_LOADING = "APP_SET_AUTH_LOADING",
  APP_SET_PAGE_ERROR = "APP_SET_PAGE_ERROR",
  APP_SET_SETTINGS = "APP_SET_SETTINGS",
  APP_SET_LOGOUT = "APP_SET_LOGOUT",
  APP_SET_PROFILE = "APP_SET_PROFILE",
}
export interface ActionReturnInterface {
  type: ReducerActionTypeInterface;
  payload?: {
    status?: boolean;
    message?: string;
    details?: any;
  };
}
