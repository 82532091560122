const config = {
  development: {
    default: "http://192.168.101.9:8080",
  },
  production: {
    default: process.env.REACT_APP_API_HOST_URL,
  },
};
export default process.env.REACT_APP_NODE_ENV === "production"
  ? config["production"]
  : config["development"];
