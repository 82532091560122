import React from "react";
import { MyButton } from "components";

import { FormConcludeButtonInterface } from "./interface";

function FormConcludeButtons({
  submitButton,
  size,
  isEdit,
  haveSubmitButton = true,
}: FormConcludeButtonInterface) {
  return (
    <div
      className={
        "flex  flex-col items-end sm:items-stretch gap-1 border-t pt-2 sm:w-full"
      }
    >
      {haveSubmitButton && (
        <MyButton
          size={size}
          disabled={submitButton?.disable}
          onClick={
            typeof submitButton?.handler === "function"
              ? () => submitButton?.handler()
              : undefined
          }
          colorType={"primary"}
          isLoading={submitButton?.isLoading}
          name={submitButton?.title || (isEdit ? "Update" : "Submit")}
          type={"submit"}
        />
      )}
    </div>
  );
}

export default FormConcludeButtons;
