const ApiUrl = {
  auth: {
    post_login: "/school/login",
    post_signup: "/school/create",
    get_profile: "/school/current",
    put_profile: "/school/edit",
    post_verifyEmail: "/school/verify-email",
    post_sendVerificationEmailCode: "/school/send-email-verification-code",
  },
  school: {
    get_lists: "/school",
    get_adminLists: "/school/admin",
    get_dashboardStats: "/school/dashboard",
    get_adminDashboardStats: "/school/admin/dashboard",
    get_setAppSetup: "/school/app-setup",
    get_details: (id: string) => `/school/details/${id}`,
    put_togglePublicStatus: `/school/toggle-public`,
    put_toggleActiveStatus: (id: string) => `/school/admin/toggle-status/${id}`,
    post_create: "/school/create",
    put_edit: "/school/edit",
    delete: (id: string) => `/school/delete/${id}`,
  },
  studentGroup: {
    get_lists: "/group/list",
    get_details: (id: string) => `/group/details/${id}`,
    post_create: "/group/create",
    put_edit: "/group/edit",
    put_toggleStatus: (id: string) => `/group/toggle-status/${id}`,
    delete: (id: string) => `/group/delete/${id}`,
  },
  student: {
    get_lists: "/group/list",
    get_details: (id: string) => `/group/details/${id}`,
    post_create: `/group/student/create-multiple`,
    put_edit: "/group/edit",
    post_uploadExel: (id: string) => `/group/upload-student/${id}`,
    delete_multiple: `/group/student/delete-multiple`,
  },
  class: {
    get_lists: `/class`,
    get_details: (id: string) => `/class/details/${id}`,
    post_create: "/class/create",
    put_edit: (id: string) => `/class/update/${id}`,
    delete: (id: string) => `/class/delete/${id}`,
    put_toggleStatus: (id: string) => `/class/toggle-status/${id}`,
  },
  exam: {
    get_lists: `/exam`,
    post_position: `/exam/seat-position`,
    get_details: (id: string) => `/exam/details/${id}`,
    post_create: "/exam/create",
    put_edit: `/exam/update`,
    delete: (id: string) => `/exam/delete/${id}`,
    put_toggleStatus: (id: string) => `/exam/toggle-status/${id}`,
  },
  feedback: {
    get_lists: `/feedback/admin`,
    get_details: (id: string) => `/feedback/details/${id}`,
    post_create: "/feedback/create",
    put_edit: `/feedback/update`,
    delete: (id: string) => `/feedback/admin/delete/${id}`,
  },
  resetPassword: {
    post_request: `/school/reset-password/request`,
    post_validate: `/school/reset-password/validate`,
    post_resetPassword: `/school/reset-password/set-password`,
  },
  logFile: {
    get_requestLogFile: "/admin/log-file/request",
    get_applicationLogFile: "/admin/log-file/application",
    get_systemLogFile: "/admin/log-file/system",
    delete_requestLogFile: "/admin/log-file/delete/request",
    delete_applicationLogFile: "/admin/log-file/delete/application",
    delete_systemLogFile: "/admin/log-file/delete/system",
  },
};

export default ApiUrl;
