import React from "react";
import { useField } from "formik";
import Select, { Props } from "react-select";
import CreatableSelect from "react-select/creatable";

import { MySelectFieldInterface } from "./interfaces";
import "./styles.css";
import { SelectOptionInterface } from "interfaces";

export default function MySelectField({
  label,
  option,
  isRequired,
  isClearable,
  customStyle,
  isCreatable,
  ...props
}: MySelectFieldInterface) {
  const [field, meta, helpers] = useField(props);
  let isError = meta.touched && meta.error;

  const isMultiple = Array.isArray(field?.value);

  const getSelectedValue = () => {
    let value;
    if (isMultiple) {
      if (option?.selectOptions?.length < 1 && field?.value?.length > 0) {
        value = field?.value?.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
      } else {
        value = option?.selectOptions?.filter((e) => {
          return field.value?.some((value) => e?.value === value);
        });
      }
    } else {
      value = option?.selectOptions?.find((e) => {
        return e?.value === field.value;
      });
    }
    return value;
  };
  const onCreateChange = (value) => {
    let tempValues = [...field?.value, value];
    helpers?.setValue(isMultiple ? tempValues : value);
  };

  let SelectProps: Props = {
    isClearable: true,
    isMulti: isMultiple,
    isDisabled: props?.disabled,
    className: "react-select-container",
    classNamePrefix: "custom-react-select",
    styles: {
      control: (provided, state) => ({
        ...provided,
        border: isError
          ? "2px solid red"
          : state.isFocused
            ? "2px solid #007bff"
            : provided.border,
        borderRadius: "5px",
        boxShadow: "none",
        // height:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        width: customStyle?.width,
        minHeight: "45px",
        borderWidth: "2px",
        borderColor: "#e0e0e0",
        "&:hover": {
          borderColor: "#007bff",
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: "0 10px",
        fontSize: "16px",
      }),
      input: (provided, state) => ({
        ...provided,
        margin: "0px",
        outline: "none",
        boxShadow: "none",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: "nowrap",
        color: "#cecece",
      }),
      indicatorSeparator: (state) => ({
        ...state,
        display: "none",
        padding: "0px 0px",
      }),

      clearIndicator: (state) => ({
        ...state,
        padding: "0px 0px",
      }),
      dropdownIndicator: (state) => ({
        ...state,
        padding: "0px 5px",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        padding: "0px 0px",
        // minHeight:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
        // height:
        //   inputSize === "sm" ? "30px" : inputSize === "md" ? "35px" : "40px",
      }),
      menuPortal: (base) => ({ ...base, zIndex: 999 }),
    },
    value: field?.value ? getSelectedValue() : isMultiple ? [] : "",
    options: option?.selectOptions,
    name: props.name,
    placeholder:
      option?.defaultLabel || (isMultiple ? "Select options" : "Select option"),
    onChange: (newValue: SelectOptionInterface[] | SelectOptionInterface) => {
      if (Array.isArray(newValue)) {
        helpers.setValue(newValue?.map((e) => e?.value));
        if (typeof option?.callbackHandler === "function") {
          option?.callbackHandler(newValue?.map((e) => e?.value));
        }
      } else {
        helpers.setValue(newValue?.value || "");
        if (typeof option?.callbackHandler === "function") {
          option?.callbackHandler(newValue?.value);
        }
      }
    },
  };

  return (
    <div className="flex flex-col justify-end input_container ">
      {(label || isRequired) && (
        <label htmlFor={props?.name} className={`input_label text-[16px]`}>
          <div>{label && <span>{label} </span>}</div>
          {isRequired && <span className={`text-red-500`}>*</span>}
        </label>
      )}
      {isCreatable ? (
        <CreatableSelect
          maxMenuHeight={200}
          onCreateOption={onCreateChange}
          {...SelectProps}
        />
      ) : (
        <Select
          menuPortalTarget={document.body}
          maxMenuHeight={200}
          {...SelectProps}
        />
      )}
      {isError && meta?.error?.trim() && (
        <span className={`input_textError text-[16px]`}>* {meta.error}</span>
      )}
    </div>
  );
}
