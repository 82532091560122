import React, { createContext, useContext, useState } from "react";
import { LogsTypeEnum } from "interfaces";
import { ApiUrl } from "services";
import { Api } from "helpers";
import { SystemContext } from "./SystemContext";

interface ContextInterface {
  isLoading: boolean;
  requestText: string;
  systemText: string;
  applicationText: string;
  getFileHandler(type: LogsTypeEnum): any;
  deleteFileHandler(type: LogsTypeEnum): any;
}
export const FileContext = createContext<ContextInterface>({
  isLoading: false,
  requestText: "",
  systemText: "",
  applicationText: "",
  getFileHandler(type): any {},
  deleteFileHandler(type: LogsTypeEnum): any {},
});
function FileContextProvider({ children }) {
  const {
    handlers: { setLoadingHandler },
  } = useContext(SystemContext);
  const [isLoading, setLoading] = useState(false);
  const { getApi, deleteApi } = Api();
  const [requestText, setRequestText] = useState("");
  const [systemText, setSystemText] = useState("");
  const [applicationText, setApplicationText] = useState("");

  const getFileHandler = async (type: LogsTypeEnum) => {
    try {
      setLoading(true);
      let res: any;
      switch (type) {
        case LogsTypeEnum.APPLICATION:
          res = await getApi(ApiUrl.logFile.get_applicationLogFile);
          setApplicationText(res);
          break;
        case LogsTypeEnum.REQUEST:
          res = await getApi(ApiUrl.logFile.get_requestLogFile);
          setRequestText(res);
          break;
        case LogsTypeEnum.SYSTEM:
          res = await getApi(ApiUrl.logFile.get_systemLogFile);
          setSystemText(res);
          break;
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const deleteFileHandler = async (type: LogsTypeEnum) => {
    try {
      const isConfirm = window.confirm("Are you sure?");
      if (!isConfirm) return;
      setLoadingHandler(true);
      switch (type) {
        case LogsTypeEnum.APPLICATION:
          await deleteApi(ApiUrl.logFile.delete_applicationLogFile);
          setApplicationText("");
          break;
        case LogsTypeEnum.REQUEST:
          await deleteApi(ApiUrl.logFile.delete_requestLogFile);
          setRequestText("");
          break;
        case LogsTypeEnum.SYSTEM:
          await deleteApi(ApiUrl.logFile.delete_systemLogFile);
          setSystemText("");
          break;
      }
    } catch (err) {
    } finally {
      setLoadingHandler(false);
    }
  };

  return (
    <FileContext.Provider
      value={{
        isLoading,
        requestText,
        systemText,
        applicationText,
        getFileHandler,
        deleteFileHandler,
      }}
    >
      {children}
    </FileContext.Provider>
  );
}

export default FileContextProvider;
