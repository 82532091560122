import {
  ActionReturnInterface,
  ReducerActionTypeInterface,
} from "./interfaces";

export const setErrorAction = (
  status: boolean,
  message?: string,
): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_ERROR,
    payload: { status, message },
  };
};
export const setSuccessAction = (
  status: boolean,
  message?: string,
): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_SUCCESS,
    payload: { status, message },
  };
};
export const setLoadingAction = (status: boolean): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_LOADING,
    payload: { status },
  };
};
export const setAuthLoadingAction = (
  status: boolean,
): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_AUTH_LOADING,
    payload: { status },
  };
};
export const setPageErrorAction = (
  status: boolean,
  message?: string,
): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_PAGE_ERROR,
    payload: { status, message },
  };
};

export const setLogoutAction = (): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_LOGOUT,
    payload: null,
  };
};
export const setProfileAction = (details: any): ActionReturnInterface => {
  return {
    type: ReducerActionTypeInterface.APP_SET_PROFILE,
    payload: { details },
  };
};
