import React from "react";
import { useApplicationHook } from "useHooks";

function AppTemplate({ children }) {
  const { fullHeight } = useApplicationHook();
  return (
    <div
      style={{
        height: fullHeight + "px",
      }}
      className={`w-screen bg-white flex items-center justify-center overflow-scroll hideScroll`}
    >
      <div
        className={
          "max-w-[1200px] w-full sm:w-[100vw] bg-white h-full overflow-scroll hideScroll "
        }
      >
        {children}
      </div>
    </div>
  );
}

export default AppTemplate;
