import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Formik } from "formik";
import { FormSubmitButtonsComponent, MyTextField } from "components";
import * as yup from "yup";
import { DataContext, StudentGroupContext } from "context";
import {
  HomeTabEnum,
  ParamsStringEnum,
  StudentGroupInterface,
} from "interfaces";
import { PageLinks } from "routes";

function EditStudentGroupPage() {
  const { group_id } = useParams<ParamsStringEnum>();

  const navigate = useNavigate();
  const {
    details: studentGroupDetails,
    isDetailsLoading,
    editHandler,
    getDetailsHandler,
    getListsHandler,
  } = useContext(StudentGroupContext);
  const {
    handlers: { getDashboardStatsHandler },
  } = useContext(DataContext);
  const submitHandler = (values: any) => {
    editHandler(values, async (apiResponse) => {
      await Promise.all([getListsHandler(), getDashboardStatsHandler()]);
      navigate(PageLinks.studentGroup.details(apiResponse?.id || group_id));
    });
  };
  useEffect(() => {
    if (group_id) {
      getDetailsHandler(group_id);
    }
  }, [group_id]);
  return (
    <PageTemplate
      isLoading={group_id && isDetailsLoading}
      backPath={PageLinks.home(HomeTabEnum.GROUPS)}
      title={group_id ? `Edit Group` : "Create Group"}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={FormValues.initialValues(
          group_id && studentGroupDetails,
        )}
        validationSchema={FormValues.validationSchema}
      >
        <Form className={"flex flex-col gap-5"}>
          <div className={"flex flex-col gap-2"}>
            <MyTextField
              name={"name"}
              label={"Name"}
              placeholder={"eg: BBA first semester 2078"}
              isRequired
            />
          </div>
          <FormSubmitButtonsComponent
            cancelButton={{
              handler() {
                navigate(PageLinks.home(HomeTabEnum.GROUPS));
              },
            }}
          />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

const FormValues = {
  initialValues: (values?: Partial<StudentGroupInterface>) => {
    return {
      id: values?.id || "",
      name: values?.name || "",
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().typeError("invalid").required("required"),
  }),
};
export default EditStudentGroupPage;
