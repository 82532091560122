import {
  ActionReturnInterface,
  AppReducerInterface,
  ReducerActionTypeInterface,
} from "./interfaces";

export const appState: AppReducerInterface = {
  isLoading: false,
  isError: false,
  isActionSuccess: false,
  isAuthenticated: false,
  isAuthLoading: true,
  havePageError: false,
  pageErrorMessage: "",
  errorMessage: "",
  successMessage: "",
  profileDetails: null,
  appSettings: null,
};
const appReducer = (
  state: AppReducerInterface,
  action: ActionReturnInterface,
) => {
  const payload = action?.payload;
  switch (action.type) {
    case ReducerActionTypeInterface.APP_SET_ERROR:
      return {
        ...state,
        isError: payload?.status,
        errorMessage: payload?.message,
      };

    case ReducerActionTypeInterface.APP_SET_SUCCESS:
      return {
        ...state,
        isActionSuccess: payload?.status,
        successMessage: payload?.message,
      };
    case ReducerActionTypeInterface.APP_SET_LOADING:
      return {
        ...state,
        isLoading: payload?.status,
      };
    case ReducerActionTypeInterface.APP_SET_AUTH_LOADING:
      return {
        ...state,
        isAuthLoading: payload?.status,
      };
    case ReducerActionTypeInterface.APP_SET_PAGE_ERROR:
      return {
        ...state,
        havePageError: payload?.status,
        pageErrorMessage: payload?.message,
      };
    case ReducerActionTypeInterface.APP_SET_SETTINGS:
      return {
        ...state,
        appSettings: payload?.details,
      };
    case ReducerActionTypeInterface.APP_SET_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        profileDetails: null,
      };
    case ReducerActionTypeInterface.APP_SET_PROFILE:
      return {
        ...state,
        profileDetails: payload?.details,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};

export default appReducer;
