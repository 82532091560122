import { useEffect, useState } from "react";

function UseApplicationHook() {
  const [isSmScreen, setSmScreen] = useState(false);
  const [fullHeight, setHeight] = useState(null);
  useEffect(() => {
    handleResize();
    function handleResize() {
      setHeight(window.innerHeight);
      setSmScreen(window.innerWidth < 500);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return { fullHeight, isSmScreen };
}

export default UseApplicationHook;
