import {
  ClassRoomContext,
  ContextProvider,
  DataContext,
  ExamContext,
  FeedbackContext,
  StudentGroupContext,
  SystemContext,
  SchoolContext,
} from "context";

import { ErrorBoundaryTemplate, AppTemplate } from "templates";
import { AuthRoutes } from "routes";
import { useContext, useEffect } from "react";
import { AppIntroModel } from "content";

function App() {
  return (
    <ErrorBoundaryTemplate>
      <ContextProvider>
        <Init>
          <AppTemplate>
            <AuthRoutes />
          </AppTemplate>
        </Init>
      </ContextProvider>
    </ErrorBoundaryTemplate>
  );
}

function Init({ children }) {
  const {
    state: { isAuthenticated, profileDetails },
  } = useContext(SystemContext);
  const {
    handlers: { getSchoolsHandler, getDashboardStatsHandler },
  } = useContext(DataContext);
  const { getListsHandler: getExamsHandler } = useContext(ExamContext);
  const { getListsHandler: getClassRoomHandler } = useContext(ClassRoomContext);
  const { getListsHandler: getGroupsHandler } = useContext(StudentGroupContext);
  const { getListsHandler: getFeedbackHandler } = useContext(FeedbackContext);
  const { getListsHandler: getSchoolHandlerAdmin } = useContext(SchoolContext);

  useEffect(() => {
    (async () => {
      await Promise.all([
        ...(isAuthenticated && !profileDetails?.is_admin
          ? [
              getExamsHandler(),
              getClassRoomHandler(),
              getGroupsHandler(),
              getDashboardStatsHandler(),
            ]
          : isAuthenticated && profileDetails?.is_admin
            ? [
                getFeedbackHandler(),
                getDashboardStatsHandler(),
                getSchoolHandlerAdmin(),
              ]
            : []),
        getSchoolsHandler(),
      ]);
    })();
  }, [isAuthenticated, profileDetails]);

  return (
    <>
      {children}
      <AppIntroModel />
    </>
  );
}

export default App;
