import React, { createContext } from "react";
import {
  DataContextInterface,
  ExamInterface,
  SchoolInterface,
} from "interfaces";
import { useDataContextHook } from "useHooks";
import { ApiUrl } from "services";

export const SchoolContext = createContext<
  DataContextInterface<SchoolInterface>
>({
  details: undefined,
  isDetailsLoading: false,
  isLoading: false,
  listObject: undefined,
  lists: [],
  deleteHandler(
    item_id: string,
    onSuccessHandler?: (payload: any) => {},
  ): any {},
  editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {},
  getDetailsHandler(item_id: string): any {},
  getListsHandler(query?: any): any {},
  toggleStatusHandler(
    itemId: string,
    onSuccessHandler?: (payload: any) => any,
  ): any {},
});
function SchoolContextProvider({ children }) {
  const {
    getListsHandler,
    getDetailsHandler,
    deleteHandler,
    editHandler,
    createHandler,
    listObject,
    isDetailsLoading,
    lists,
    isLoading,
    details,
    toggleStatusHandler,
  } = useDataContextHook();
  const handlers = {
    getDetailsHandler: (item_id: string) => {
      return getDetailsHandler(ApiUrl.exam.get_details(item_id));
    },
    deleteHandler(
      item_id: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return deleteHandler(ApiUrl.exam.delete(item_id), onSuccessHandler);
    },
    editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {
      return payload?.id
        ? editHandler(ApiUrl.exam.put_edit, payload, onSuccessHandler)
        : createHandler(ApiUrl.exam.post_create, payload, onSuccessHandler);
    },
    getListsHandler(query?: any): any {
      return getListsHandler(ApiUrl.school.get_adminLists, query);
    },
    toggleStatusHandler(
      itemId: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return toggleStatusHandler(
        ApiUrl.school.put_toggleActiveStatus(itemId),
        {},
        onSuccessHandler,
      );
    },
  };
  return (
    <SchoolContext.Provider
      value={{
        isLoading,
        isDetailsLoading,
        lists,
        details,
        listObject,
        ...handlers,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
}

export default SchoolContextProvider;
