import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

import { IconTypeEnum, TableInterface, TableRowInterface } from "interfaces";
import "./styles.css";
import { getIconsHandler } from "../../helpers";

const TableComponent = ({
  loading,
  column,
  dataSource,
  onRowClickHandler,
  rowSelectConfiguration,
}: TableInterface) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const CheckedBox = getIconsHandler(IconTypeEnum.CHECK);
  const UnCheckedBox = getIconsHandler(IconTypeEnum.UN_CHECK);
  const canSelectRow =
    typeof rowSelectConfiguration?.onSelectRows === "function";
  const onSelectRowsHandler = (row: any) => {
    let tempRows = [...selectedRows];
    if (tempRows?.some((_e) => _e == row)) {
      tempRows = tempRows?.filter((_e) => _e != row);
    } else {
      tempRows.push(row);
    }
    setSelectedRows(tempRows);
    typeof rowSelectConfiguration.onSelectRows === "function" &&
      rowSelectConfiguration.onSelectRows(tempRows);
  };
  const onCheckAllHandler = () => {
    const isAllSelected = selectedRows?.length === dataSource?.length;
    if (isAllSelected) {
      setSelectedRows([]);
      typeof rowSelectConfiguration.onSelectRows === "function" &&
        rowSelectConfiguration.onSelectRows([]);
    } else {
      setSelectedRows(dataSource);
      typeof rowSelectConfiguration.onSelectRows === "function" &&
        rowSelectConfiguration.onSelectRows(dataSource);
    }
  };
  if (!loading?.isLoading && dataSource?.length < 1) {
    return <div className="text-center w-full  p-2 text-black ">No data</div>;
  }
  return (
    <>
      <table className="table text-left border w-full text-[14px] rounded-md  table-auto ">
        <thead className="table_head  bg-gray-100 border border-collapse text-black">
          {canSelectRow && (
            <th
              scope="col"
              className="border border-collapse"
              onClick={() => onCheckAllHandler()}
            >
              {dataSource.length == selectedRows?.length ? (
                <CheckedBox />
              ) : (
                <UnCheckedBox />
              )}
            </th>
          )}
          {column.map((e, index) => {
            if (!e) return;
            return (
              <th scope="col" className="border border-collapse" key={index}>
                {e?.title}
              </th>
            );
          })}
        </thead>
        <tbody>
          {loading?.isLoading
            ? Array.apply(0, new Array(loading?.loadingRow || 8)).map(
                (e, key) => {
                  return (
                    <TableRow
                      key={key}
                      index={key}
                      column={column}
                      isLoading={true}
                    />
                  );
                },
              )
            : dataSource?.map((item, key) => (
                <TableRow
                  rowSelectConfiguration={{
                    canSelectRow,
                    selectedRows: selectedRows,
                    onSelectRows: onSelectRowsHandler,
                  }}
                  rowClickHandler={onRowClickHandler}
                  key={key}
                  index={key}
                  column={column}
                  isLoading={false}
                  item={item}
                />
              ))}
        </tbody>
      </table>
    </>
  );
};

export default TableComponent;

export const TableRow = ({
  index,
  column,
  isLoading,
  item,
  rowSelectConfiguration,
}: TableRowInterface) => {
  const CheckedBox = getIconsHandler(IconTypeEnum.CHECK);
  const UnCheckedBox = getIconsHandler(IconTypeEnum.UN_CHECK);
  const canSelectRow = rowSelectConfiguration?.canSelectRow;
  const isSelected = rowSelectConfiguration?.selectedRows?.find(
    (e) => e == item,
  );
  return (
    <tr
      onClick={() =>
        typeof rowSelectConfiguration?.onSelectRows === "function" &&
        rowSelectConfiguration.onSelectRows(item)
      }
      className={`table_row `}
    >
      {canSelectRow && (
        <td
          className={"border-b"}
          onClick={() => rowSelectConfiguration?.onSelectRows(item)}
        >
          {isLoading ? (
            <div data-testid={"loading-skeleton"}>
              <Skeleton />
            </div>
          ) : (
            <>{isSelected ? <CheckedBox /> : <UnCheckedBox />}</>
          )}
        </td>
      )}
      {column?.map((e, key) => {
        if (!e) {
          return;
        }
        return (
          <td key={key} className={"border border-collapse"}>
            {isLoading ? (
              <div data-testid={"loading-skeleton"}>
                <Skeleton />
              </div>
            ) : (
              typeof e?.render === "function" && e?.render(item, index)
            )}
          </td>
        );
      })}
    </tr>
  );
};
