import React, { useContext, useEffect, useState } from "react";
import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Formik, useFormikContext } from "formik";
import { BoxModal, MyButton, MyCheckField, MyTextField } from "components";
import * as yup from "yup";
import {
  ClassRoomContext,
  DataContext,
  ExamContext,
  StudentGroupContext,
} from "context";
import {
  ExamInterface,
  HomeTabEnum,
  IconTypeEnum,
  ParamsStringEnum,
} from "interfaces";
import { PageLinks } from "routes";
import { getIconsHandler } from "helpers";

function EditExamPage() {
  const { exam_id } = useParams<ParamsStringEnum>();

  const navigate = useNavigate();
  const {
    details: examDetails,
    getDetailsHandler,
    getListsHandler,
    editHandler,
  } = useContext(ExamContext);
  const { lists: classRooms, listObject: classroomObject } =
    useContext(ClassRoomContext);
  const { lists: studentGroups } = useContext(StudentGroupContext);
  const {
    handlers: { getDashboardStatsHandler },
  } = useContext(DataContext);

  const submitHandler = (values: any) => {
    editHandler(values, async (apiResponse) => {
      await Promise.all([getListsHandler(), getDashboardStatsHandler()]);
      navigate(PageLinks.exam.details(apiResponse?.id || exam_id));
    });
  };

  useEffect(() => {
    if (exam_id) {
      getDetailsHandler(exam_id);
    }
  }, [exam_id]);
  return (
    <PageTemplate
      backPath={PageLinks.home(HomeTabEnum.EXAMS)}
      title={exam_id ? `Edit Exam` : "Create Exam"}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={FormValues.initialValues(
          exam_id && {
            id: exam_id || "",
            ...examDetails,
          },
        )}
        validationSchema={FormValues.validationSchema}
      >
        {({ values, setFieldValue }) => {
          const getClassRoomCapacity = (classRoomId: string[]): number => {
            let capacity = 0;
            classRoomId?.forEach((id) => {
              let details = classroomObject[id];
              capacity += details?.total_space || 0;
            });
            return capacity;
          };
          const getTotalStudents = (groupId: string[]): number => {
            let capacity = 0;
            groupId?.forEach((id) => {
              let details = studentGroups?.find((e) => e?.id == id);
              capacity += details?.total_students || 0;
            });
            return capacity;
          };
          const onSelectHandler = (
            id: string,
            type: "classrooms" | "student_groups",
          ) => {
            let data =
              type == "classrooms"
                ? [...values?.classrooms]
                : [...values?.student_groups];
            const isSelected = data?.some((e: any) => e == id);
            if (isSelected) {
              data = data?.filter((e) => e !== id);
            } else {
              data.push(String(id));
            }
            setFieldValue(type, data);

            if (type === "student_groups") {
              let seatPlacementGroups: string[][] = [
                ...values.seatPlacementGroups,
              ];
              let isExists = seatPlacementGroups.flat().some((e) => e == id);
              if (isExists) {
                const index = seatPlacementGroups.findIndex((e) =>
                  e?.some((_e) => _e == id),
                );
                seatPlacementGroups[index] = seatPlacementGroups[index].filter(
                  (e) => e !== id,
                );
              } else {
                seatPlacementGroups.push([id]);
              }
              seatPlacementGroups = seatPlacementGroups.filter(
                (e) => e?.length,
              );
              setFieldValue("seatPlacementGroups", seatPlacementGroups);
            }
          };
          const totalStudents = getTotalStudents(values.student_groups);
          const totalClassroomCapacity = getClassRoomCapacity(
            values?.classrooms,
          );
          const canSubmit =
            totalStudents <= totalClassroomCapacity && totalStudents !== 0;
          return (
            <Form className={"flex flex-col gap-10 h-full"}>
              <MyTextField
                name={"name"}
                label={"Name"}
                placeholder={"eg: First Terminal Exam"}
                isRequired
              />
              <ListComponent
                title={"Classrooms"}
                data={classRooms?.map((e) => {
                  return {
                    id: e?.id,
                    name: e?.name,
                    capacity: e?.total_space,
                    active: e?.active,
                  };
                })}
                type={"classrooms"}
                selectedData={values?.classrooms}
                onSelect={(id) => onSelectHandler(id, "classrooms")}
              />
              <ListComponent
                title={"Student Groups"}
                type={"student_groups"}
                data={studentGroups?.map((e) => {
                  return {
                    id: e?.id,
                    name: e?.name,
                    capacity: e?.total_students,
                    active: e?.active,
                  };
                })}
                selectedData={values?.student_groups}
                onSelect={(id) => onSelectHandler(id, "student_groups")}
              />
              <div
                className={
                  "text-[14px] flex flex-col gap-2 bg-gray-50 p-2 rounded-md"
                }
              >
                <div className={"flex items-center justify-between"}>
                  <span>Total Students</span>
                  <span>{totalStudents}</span>
                </div>
                <div className={"flex items-center justify-between"}>
                  <span>Classroom Capacity</span>
                  <span>{totalClassroomCapacity}</span>
                </div>
              </div>
              {canSubmit ? (
                <ExamConfigurationForm />
              ) : (
                <div className={"text-red-500 text-[14px]"}>
                  *Classroom capacity must be greater than students
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

const ExamConfigurationForm = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<any>();
  const [isModalOpen, setModal] = useState(false);
  const { listObject: studentGroupsObj } = useContext(StudentGroupContext);
  const [selectedGroups, setSelectedGroups] = useState<
    {
      positionId: number;
      id: string;
    }[]
  >([]);
  const onSaveHandler = () => {
    let tempData = [...values.seatPlacementGroups];
    let index = (() => {
      let tempIndex = -1;
      let currentLoopPosition = 0;
      while (currentLoopPosition < selectedGroups?.length) {
        const position = selectedGroups[currentLoopPosition].positionId;
        if (tempData[position].length == 1) {
          tempIndex = position;
          break;
        }
        currentLoopPosition++;
      }
      return tempIndex;
    })();
    selectedGroups?.forEach((e) => {
      tempData[e.positionId] = tempData[e.positionId].filter(
        (_e) => _e !== e?.id,
      );
    });
    if (index == -1) {
      tempData.push(selectedGroups?.map((e) => e?.id));
    } else {
      selectedGroups?.map((e) => {
        tempData[index].push(e?.id);
      });
    }
    tempData = tempData.filter((e) => e?.length);
    setFieldValue("seatPlacementGroups", tempData);
    setSelectedGroups([]);
  };
  const onGroupHandler = (id: string, positionId: number) => {
    let data = [...selectedGroups];
    const isSelected = data?.some((e) => e.id == id);

    if (isSelected) {
      data = data?.filter((e) => e.id !== id);
    } else {
      data.push({
        id,
        positionId,
      });
    }
    setSelectedGroups(data);
  };
  const colors = [
    "#D63031",
    "#0A79DF",
    "#67E6DC",
    "#192A56",
    "#218F76",
    "#F4C724",
    "#2C3335",
    "#E74292",
    "#2B2B52",
    "#EA7773",
    "#badc57",
    "#30336B",
  ];
  const UnCheckBox = getIconsHandler(IconTypeEnum.UN_CHECK);
  const CheckBox = getIconsHandler(IconTypeEnum.CHECK);

  return (
    <>
      <div className={"flex items-end"}>
        <MyButton
          name={"Next"}
          type={"button"}
          colorType={"blue"}
          onClick={() => setModal(true)}
        />
      </div>

      {isModalOpen && (
        <BoxModal
          closeHandler={() => setModal(false)}
          title={"Group Seating Instructions"}
        >
          <div className={"flex w-full flex-col gap-2"}>
            <>
              <div
                className={
                  "flex flex-col gap-2 text-[14px] bg-green-100 px-4 py-2 rounded-lg"
                }
              >
                <div>
                  <li>
                    Students in the same group will be seated together on the
                    same bench.
                  </li>
                  <li>Select student groups to seat them together.</li>
                </div>
              </div>
              <div>
                <MyCheckField
                  name={"is_separate_classroom"}
                  label={"Separate classroom for different group"}
                />
              </div>
              <div className={"flex flex-col  border rounded-md"}>
                <div className={"flex justify-between border-b p-2"}>
                  <div className={"font-bold text-[18px]"}>
                    Seat Placement Groups
                  </div>
                  <div>
                    {selectedGroups?.length > 0 && (
                      <MyButton
                        size={"sm"}
                        colorType={"blue"}
                        onClick={onSaveHandler}
                        name={"Make Group"}
                      />
                    )}
                  </div>
                </div>
                <div className={"flex flex-col gap-2  p-2"}>
                  {values?.seatPlacementGroups?.map((e: any, key: any) => {
                    return (
                      <div className={"flex flex-col"}>
                        {e?.map((_e: any, _key: any) => {
                          const isSelected = selectedGroups?.find((groups) => {
                            return groups?.id == _e;
                          });
                          return (
                            <div
                              key={_key}
                              className={"flex gap-4 w-full items-center "}
                            >
                              <div onClick={() => onGroupHandler(_e, key)}>
                                {isSelected ? <CheckBox /> : <UnCheckBox />}
                              </div>
                              <div
                                className={"h-[30px] flex items-center gap-2"}
                              >
                                <div
                                  className={"w-[4px] h-full"}
                                  style={{
                                    backgroundColor: colors[key],
                                  }}
                                ></div>
                                {studentGroupsObj[_e]?.name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
            <MyButton type={"submit"} name={"Submit"} onClick={submitForm} />
          </div>
        </BoxModal>
      )}
    </>
  );
};

function ListComponent({
  title,
  data,
  selectedData,
  onSelect,
  type,
}: {
  title: string;
  data: { id: string; name: string; capacity: number; active: boolean }[];
  selectedData: string[];
  onSelect(id: string): any;
  type?: "classrooms" | "student_groups";
}) {
  const CheckIcon = getIconsHandler(IconTypeEnum.CHECK);
  const UnCheckIcon = getIconsHandler(IconTypeEnum.UN_CHECK);
  const navigate = useNavigate();
  return (
    <div className={"flex flex-col gap-2 "}>
      <div className={"flex justify-between"}>
        <div className={"font-bold text-[18px]"}>{title}</div>
      </div>
      {data?.length < 1 ? (
        <div className={"flex items-center"}>
          <MyButton
            colorType={"blue"}
            isOutline
            iconType={IconTypeEnum.ADD}
            onClick={() => {
              navigate(
                type == "student_groups"
                  ? PageLinks.studentGroup.create
                  : PageLinks.class.create,
              );
            }}
            name={`Add ${type == "student_groups" ? "Student Group" : "Classroom"}`}
          />
        </div>
      ) : (
        <div className={"flex flex-col gap-2 border rounded-md"}>
          <table className={"table"}>
            <thead className={"border-b"}>
              <th></th>
              <th className={"text-left px-2 py-1"}>Name</th>
              <th className={"text-left px-2 py-1"}>
                {type === "classrooms" ? "Capacity" : "Total Students"}
              </th>
            </thead>
            <tbody>
              {data
                ?.filter((e) => e?.active)
                ?.map((e, key) => {
                  const isSelected = selectedData?.some(
                    (_e: any) => _e == e?.id,
                  );
                  return (
                    <tr key={key} onClick={() => onSelect(e?.id)}>
                      <td className={"px-2 py-1 w-[20px] cursor-pointer"}>
                        {isSelected ? <CheckIcon /> : <UnCheckIcon />}
                      </td>
                      <td className={"px-2 py-1"}>{e?.name}</td>
                      <td className={"px-2 py-1"}>{e?.capacity || 0}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const FormValues = {
  initialValues: (values?: Partial<ExamInterface>) => {
    return {
      ...(values?.id
        ? {
            id: values?.id,
          }
        : {}),
      name: values?.name || "",
      classrooms: values?.class_rooms || [],
      student_groups: values?.student_groups || [],
      seatPlacementGroups: values?.seatPlacementGroups || [],
      is_separate_classroom: values?.is_separate_classroom || false,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().typeError("invalid").required("required"),
  }),
};
export default EditExamPage;
