import { useContext, useState } from "react";
import { Api } from "helpers";
import { SystemContext } from "context";

function UseDataContextHook() {
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [details, setDetails] = useState(undefined);
  const [lists, setLists] = useState([]);
  const [listObject, setListObject] = useState({});
  const {
    handlers: { setErrorHandler, setLoadingHandler, setSuccessHandler },
  } = useContext(SystemContext);
  const { getApi, postApi, putApi, deleteApi, postFormApi } = Api();
  const getListsHandler = async function (url: string, query?: any) {
    try {
      setLoading(true);
      const res = await getApi(url, query);
      let obj = {};
      res?.data?.forEach((e) => {
        obj[e?.id] = e;
      });
      setListObject(obj);
      setLists(res?.data);
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const getDetailsHandler = async function (url: string) {
    try {
      setDetailsLoading(true);
      const res = await getApi(url);
      setDetails(res?.data);
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setDetailsLoading(false);
    }
  };
  const deleteHandler = async function (url: string, onSuccessHandler?: any) {
    try {
      const isConfirm = window.confirm("Are you sure?");
      if (!isConfirm) return;
      setLoadingHandler(true);
      await deleteApi(url);
      setSuccessHandler(true, "Deleted successfully");
      if (typeof onSuccessHandler === "function") {
        onSuccessHandler();
      }
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoadingHandler(false);
    }
  };
  const createHandler = async function (
    url: string,
    payload: any,
    onSuccessHandler: any,
    isFormData?: boolean,
  ) {
    try {
      setLoadingHandler(true);
      let res: any;
      if (isFormData) {
        res = await postFormApi(url, payload);
      } else {
        res = await postApi(url, payload);
      }
      setSuccessHandler(true, "Created successfully");

      if (typeof onSuccessHandler === "function") {
        onSuccessHandler(res?.data);
      }
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoadingHandler(false);
    }
  };
  const editHandler = async function (
    url: string,
    payload: any,
    onSuccessHandler: any,
    customSuccessMessage?: string,
  ) {
    try {
      setLoadingHandler(true);
      const res = await putApi(url, payload);
      setSuccessHandler(true, customSuccessMessage || "Updated successfully");
      if (typeof onSuccessHandler === "function") {
        onSuccessHandler(res?.data);
      }
    } catch (err) {
      setErrorHandler(true, err?.message);
    } finally {
      setLoadingHandler(false);
    }
  };
  const toggleStatusHandler = async function (
    url: string,
    payload: any,
    onSuccessHandler: any,
  ) {
    try {
      setLoadingHandler(true);
      await putApi(url, payload);
      if (typeof onSuccessHandler === "function") {
        onSuccessHandler();
      }
    } catch (err) {
    } finally {
      setLoadingHandler(false);
    }
  };

  return {
    details,
    isDetailsLoading,
    isLoading,
    lists,
    listObject,
    createHandler,
    deleteHandler,
    editHandler,
    getDetailsHandler,
    getListsHandler,
    toggleStatusHandler,
  };
}

export default UseDataContextHook;
