import React from "react";
import { MyButtonInterface } from "./interfaces";
import { getIconsHandler } from "helpers";

const MyButton: React.FC<MyButtonInterface> = ({
  name,
  isOutline,
  isLoading,
  iconType,
  colorType,
  className,
  iconClassName,
  size,
  ...props
}: MyButtonInterface) => {
  const getButtonStyle = () => {
    let styles: string, iconStyle: string;
    switch (colorType) {
      case "primary":
        if (isOutline) {
          styles = " text-primary hover:bg-gray-100 focus:outline-tBlue";
          iconStyle = "text-primary fill-primary";
        } else {
          styles = "bg-primary  text-white focus:outline-tRed  ";
          iconStyle = "text-white fill-white";
        }
        break;
      case "blue":
        if (isOutline) {
          styles = " text-tBlue focus:outline-tRed";
          iconStyle = "text-tBlue fill-tBlue";
        } else {
          styles = "bg-tBlue  text-white focus:outline-tRed ";
          iconStyle = "text-white fill-white";
        }
        break;
      case "secondary":
        if (isOutline) {
          styles =
            "bg-transparent text-secondary border-secondary focus:outline-tRed";
          iconClassName = "text-secondary fill-secondary";
        } else {
          styles = "bg-secondary text-black focus:outline-tRed";
          iconClassName = "text-black fill-black";
        }
        break;
      case "danger":
        if (isOutline) {
          styles = " text-tRed focus:outline-tBlue";
          iconClassName = "text-tRed fill-tRed";
        } else {
          styles = "bg-tRed  text-white focus:outline-tBlue";
          iconClassName = "text-white fill-white";
        }
        break;
      case "white":
        styles = "  bg-white text-black focus:outline-tRed";
        iconStyle = "text-black";

        break;
      default:
        if (isOutline) {
          styles = " text-primary hover:bg-gray-100 focus:outline-tBlue";
          iconStyle = "text-primary fill-primary";
        } else {
          styles = "bg-primary  text-white  focus:outline-tRed";
          iconStyle = "text-white fill-white";
        }
    }
    return { styles, iconStyle };
  };
  const { styles, iconStyle } = getButtonStyle();
  const Icon = getIconsHandler(iconType);
  const isContainRounded = className?.includes("rounded");
  return (
    <button
      type={"button"}
      style={{
        minHeight: size == "sm" ? "24px" : "40px",
        borderRadius: size == "sm" ? "5px" : "10px",
        fontSize: size == "sm" ? "12px" : "14px",
      }}
      className={`btn flex-1 hover:opacity-95   cursor-pointer  box-border
    text-[14px]   px-3  ${styles}  ${
      (props?.disabled || isLoading) && "cursor-not-allowed opacity-90"
    } ${!isContainRounded && "rounded-md"}     ${className}`}
      {...props}
      disabled={props?.disabled || isLoading}
      onClick={props?.onClick}
    >
      <div className={"flex  items-center justify-center  gap-1"}>
        {iconType && <Icon className={` text-[22px]  ${iconStyle} `} />}
        {name && (
          <span className={"whitespace-nowrap font-semibold tracking-widest "}>
            {isLoading ? "Please wait..." : name}
          </span>
        )}
      </div>
    </button>
  );
};

export default MyButton;
