import React, { useContext, useEffect } from "react";

import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import {
  ClassPage,
  ClassDetailsPage,
  LoginPage,
  HomePage,
  EditClassPage,
  ExamPage,
  EditExamPage,
  ExamDetailsPage,
  FindPositionPage,
  VerifyEmailPage,
  EditStudentGroupPage,
  StudentGroupPage,
  EditStudentPage,
  StudentGroupDetailsPage,
  Page404,
  UploadStudentExcelPage,
  AdminDashboardPage,
  BlockedPage,
  ForgotPasswordValidatePage,
  ForgotPasswordSetPasswordPage,
  ForgotPasswordRequestPage,
} from "pages";
import { ParamsStringEnum } from "interfaces";
import { SystemContext } from "context";
import { PageLinks } from "./index";
import { Loader } from "components";
import { useApplicationHook } from "useHooks";

export default function AuthRoute() {
  const {
    state: { isAuthenticated, isAuthLoading, profileDetails },
  } = useContext(SystemContext);
  const { fullHeight } = useApplicationHook();
  if (isAuthLoading) {
    return (
      <div style={{ height: fullHeight }} className={"bg-white h-full w-full"}>
        <Loader />
      </div>
    );
  }
  return (
    <Routes>
      <Route element={<RouteProtection isPublic />}>
        <Route path={"/"} element={<LoginPage />} />
        <Route path={"/signup"} element={<LoginPage />} />
        <Route path={"find-position"} element={<FindPositionPage />} />
        <Route
          path={"/reset-password/request"}
          element={<ForgotPasswordRequestPage />}
        />
        <Route
          path={`/reset-password/:${ParamsStringEnum.TYPE}`}
          element={<ForgotPasswordValidatePage />}
        />
        <Route
          path={"/reset-password/set-password"}
          element={<ForgotPasswordSetPasswordPage />}
        />
      </Route>
      {isAuthenticated && profileDetails?.active && (
        <Route element={<RouteProtection isPublic={false} />}>
          <Route path={"/email-verification"} element={<VerifyEmailPage />} />
          {profileDetails?.is_admin ? (
            <>
              <Route
                path={"/admin/dashboard"}
                element={<AdminDashboardPage />}
              />
            </>
          ) : (
            <>
              <Route path={"/home"} element={<HomePage />} />
              <Route path={"school"}>
                <Route path={"class"}>
                  <Route path={``} element={<ClassPage />} />
                  <Route
                    path={`details/:${ParamsStringEnum.CLASS_ID}`}
                    element={<ClassDetailsPage />}
                  />
                  <Route path={`create`} element={<EditClassPage />} />
                  <Route
                    path={`edit/:${ParamsStringEnum.CLASS_ID}`}
                    element={<EditClassPage />}
                  />
                </Route>
                <Route path={"exam"}>
                  <Route path={``} element={<ExamPage />} />
                  <Route
                    path={`details/:${ParamsStringEnum.EXAM_ID}`}
                    element={<ExamDetailsPage />}
                  />
                  <Route path={`create`} element={<EditExamPage />} />
                  <Route
                    path={`edit/:${ParamsStringEnum.EXAM_ID}`}
                    element={<EditExamPage />}
                  />
                </Route>
              </Route>
              <Route path={"student-group"}>
                <Route path={``} element={<StudentGroupPage />} />
                <Route
                  path={`details/:${ParamsStringEnum.GROUP_ID}`}
                  element={<StudentGroupDetailsPage />}
                />
                <Route path={`create`} element={<EditStudentGroupPage />} />
                <Route
                  path={`edit/:${ParamsStringEnum.GROUP_ID}`}
                  element={<EditStudentGroupPage />}
                />{" "}
                <Route
                  path={`add-students/:${ParamsStringEnum.GROUP_ID}`}
                  element={<EditStudentPage />}
                />
                <Route
                  path={`update-excel/:${ParamsStringEnum.GROUP_ID}`}
                  element={<UploadStudentExcelPage />}
                />
              </Route>
            </>
          )}
        </Route>
      )}
      {isAuthenticated && !profileDetails?.active && (
        <Route path={"/page-blocked"} element={<BlockedPage />} />
      )}
      <Route element={<Page404 />} path={"*"} />
    </Routes>
  );
}

const RouteProtection = ({ isPublic }) => {
  const isPrivate = !isPublic;
  const {
    state: { isAuthenticated, isAuthLoading, profileDetails },
  } = useContext(SystemContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isPrivate && !isAuthenticated && !isAuthLoading) {
      navigate(PageLinks.login);
      return;
    }
    if (isPublic && isAuthenticated) {
      if (!profileDetails?.active) {
        navigate(PageLinks.pageBlocked);
      } else if (profileDetails?.is_admin) {
        navigate(PageLinks.adminDashboard);
      } else {
        navigate(PageLinks.home());
      }
    }
  }, [isPublic, isPrivate, isAuthenticated, isAuthLoading]);

  if ((isPrivate && isAuthenticated) || (isPublic && !isAuthenticated)) {
    return <Outlet />;
  }
};
