import { IconTypeEnum } from "interfaces";
import { BiMessageRounded } from "react-icons/bi";
import {
  IoMusicalNoteSharp,
  IoSettingsOutline,
  IoHomeOutline,
  IoClose,
  IoRadioButtonOnSharp,
  IoRadioButtonOffSharp,
  IoInformationOutline,
  IoArrowBackOutline,
  IoReload,
  IoPrintSharp,
} from "react-icons/io5";
import { FaRegHeart, FaHeart, FaPlay, FaRegStar } from "react-icons/fa";
import {
  HiOutlineNewspaper,
  HiOutlineUser,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { GoHome } from "react-icons/go";
import { FiEdit, FiMessageSquare } from "react-icons/fi";
import { RiTranslate2, RiRobotLine, RiFileExcel2Line } from "react-icons/ri";
import {
  MdAdd,
  MdAddchart,
  MdLogout,
  MdOutlineAddBusiness,
  MdOutlineDeleteOutline,
  MdOutlineGroupAdd,
  MdOutlineLibraryAddCheck,
  MdOutlineSchool,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import {
  TbGitPullRequest,
  TbArrowsVertical,
  TbFaceIdError,
} from "react-icons/tb";
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from "react-icons/ai";
import { IoIosList, IoIosSearch, IoMdSend } from "react-icons/io";
import { TfiLayoutGrid4, TfiWrite } from "react-icons/tfi";
import { CgNotes } from "react-icons/cg";

const getIconsHandler = (type: IconTypeEnum) => {
  switch (type) {
    case IconTypeEnum.CREATE_ROOM:
      return MdOutlineAddBusiness;
    case IconTypeEnum.EXCEL:
      return RiFileExcel2Line;
    case IconTypeEnum.VIDEO:
      return MdOutlineVideoLibrary;
    case IconTypeEnum.PRINT:
      return IoPrintSharp;
    case IconTypeEnum.EDIT:
      return FiEdit;
    case IconTypeEnum.FORM:
      return TfiWrite;
    case IconTypeEnum.CREATE_GROUP:
      return MdOutlineGroupAdd;
    case IconTypeEnum.CREATE_EXAM:
      return MdAddchart;
    case IconTypeEnum.LOGOUT:
      return MdLogout;
    case IconTypeEnum.EXAM:
      return CgNotes;
    case IconTypeEnum.CLASSROOM:
      return TfiLayoutGrid4;
    case IconTypeEnum.STUDENT_GROUP:
      return HiOutlineUserGroup;
    case IconTypeEnum.TRANSLATE:
      return RiTranslate2;
    case IconTypeEnum.MESSAGE:
      return FiMessageSquare;
    case IconTypeEnum.RELOAD:
      return IoReload;
    case IconTypeEnum.RATING:
      return FaRegStar;
    case IconTypeEnum.SEARCH:
      return IoIosSearch;
    case IconTypeEnum.SEND:
      return IoMdSend;
    case IconTypeEnum.ROBOT:
      return RiRobotLine;
    case IconTypeEnum.USER:
      return MdOutlineSchool;
    case IconTypeEnum.ERROR:
      return TbFaceIdError;
    case IconTypeEnum.FEEDBACK:
      return BiMessageRounded;
    case IconTypeEnum.DELETE:
      return MdOutlineDeleteOutline;
    case IconTypeEnum.PAUSE:
      return FaPlay;
    case IconTypeEnum.LIST:
      return IoIosList;
    case IconTypeEnum.FULL_SCREEN_CLOSE:
      return AiOutlineFullscreenExit;
    case IconTypeEnum.FULL_SCREEN_OPEN:
      return AiOutlineFullscreen;
    case IconTypeEnum.INFO:
      return IoInformationOutline;
    case IconTypeEnum.ADD:
      return MdAdd;
    case IconTypeEnum.LOVE:
      return FaRegHeart;
    case IconTypeEnum.LOVE_FILL:
      return FaHeart;
    case IconTypeEnum.BACK:
      return IoArrowBackOutline;
    case IconTypeEnum.CONTRIBUTE:
      return TbGitPullRequest;
    case IconTypeEnum.HOME:
      return IoHomeOutline;
    case IconTypeEnum.SETTING:
      return IoSettingsOutline;
    case IconTypeEnum.MUSIC:
      return IoMusicalNoteSharp;
    case IconTypeEnum.DASHBOARD:
      return GoHome;
    case IconTypeEnum.LOGIN:
      return HiOutlineUser;
    case IconTypeEnum.CLOSE:
      return IoClose;
    case IconTypeEnum.CHECK:
      return ImCheckboxChecked;
    case IconTypeEnum.UN_CHECK:
      return ImCheckboxUnchecked;
    case IconTypeEnum.SCROLL:
      return TbArrowsVertical;
    case IconTypeEnum.TOGGLE_OFF:
      return BsToggleOff;
    case IconTypeEnum.TOGGLE_ON:
      return BsToggleOn;
    case IconTypeEnum.RADIO:
      return IoRadioButtonOnSharp;
    case IconTypeEnum.RADIO_CIRCLE:
      return IoRadioButtonOffSharp;
    default:
      return IoMusicalNoteSharp;
  }
};
export default getIconsHandler;
