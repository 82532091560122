import React, { useContext } from "react";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { DataContext } from "context";
import { useApplicationHook } from "useHooks";
import { Form, Formik, FormikBag } from "formik";
import { MyButton, MyTextField } from "components";
import { getIconsHandler } from "helpers";
import { IconTypeEnum, ResetPasswordParamsEnum } from "interfaces";

function ForgotPasswordRequestPage() {
  const {
    handlers: { requestResetPasswordHandler },
  } = useContext(DataContext);
  const navigate = useNavigate();
  const onSubmitHandler = (values: any, props: FormikBag<any, any>) => {
    requestResetPasswordHandler(
      values,
      () => {
        navigate({
          pathname: PageLinks.resetPassword.custom_message(
            ResetPasswordParamsEnum.CHECK_EMAIL,
          ),
          search: `?email=${values?.email}`,
        });
      },
      (message) => {
        props?.setFieldError("email", message);
      },
    );
  };
  const { fullHeight } = useApplicationHook();
  const BackIcon = getIconsHandler(IconTypeEnum.BACK);

  return (
    <div style={{ height: fullHeight }} className={"h-full w-full "}>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{
          email: "",
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required(" "),
        })}
      >
        <Form
          className={
            " flex flex-col h-full w-full p-4 justify-between items-start"
          }
        >
          <div className={"flex flex-col items-center gap-20 w-full"}>
            <div className={"w-full"}>
              <a href={"http://examseatplanner.com"} target={"_blank"}>
                <img
                  src={"/img/logo/logo.svg"}
                  className={"h-[60px] sm:h-[40px] object-contain"}
                  alt={"icon"}
                />
              </a>
            </div>

            <div className={"flex flex-col gap-5 w-[400px] sm:w-full"}>
              <div className={"flex flex-col items-center "}>
                <div className={"text-center  text-[20px] uppercase font-bold"}>
                  Forgot password?
                </div>
                <div className={"text-[14px] text-gray-500"}>
                  Enter your email and we will reset your password
                </div>
              </div>

              <MyTextField
                name={"email"}
                label={"Email"}
                isRequired
                placeholder={"Enter email"}
              />
              <MyButton name={"Reset Password"} type={"submit"} />
              <div
                onClick={() => navigate(PageLinks.login)}
                className={
                  "flex w-full justify-center cursor-pointer items-center gap-2 text-[14px] text-gray-500"
                }
              >
                <BackIcon />
                Back to login
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ForgotPasswordRequestPage;
