import React from "react";
import "./styles.css";
function LoadingComponent({ title }: { title?: string }) {
  return (
    <div className={"flex flex-col gap-2 items-center"}>
      <span className="text-loader"></span>
      <span className={"text-gray-400"}>{title || "Please wait..."}</span>
    </div>
  );
}

export default LoadingComponent;
