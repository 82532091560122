import React, { createContext } from "react";
import { DataContextInterface, FeedbackInterface } from "interfaces";
import { useDataContextHook } from "useHooks";
import { ApiUrl } from "services";

export const FeedbackContext = createContext<
  DataContextInterface<FeedbackInterface>
>({
  isLoading: false,
  lists: [],
  deleteHandler(
    item_id: string,
    onSuccessHandler?: (payload: any) => {},
  ): any {},
  editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {},
  getListsHandler(query?: any): any {},
});
function FeedbackContextProvider({ children }) {
  const {
    getListsHandler,
    deleteHandler,
    editHandler,
    createHandler,
    lists,
    isLoading,
  } = useDataContextHook();
  const handlers = {
    deleteHandler(
      item_id: string,
      onSuccessHandler?: (payload: any) => {},
    ): any {
      return deleteHandler(ApiUrl.feedback.delete(item_id), onSuccessHandler);
    },
    editHandler(payload: any, onSuccessHandler?: (payload: any) => {}): any {
      return payload?.id
        ? editHandler(ApiUrl.feedback.put_edit, payload, onSuccessHandler)
        : createHandler(ApiUrl.feedback.post_create, payload, onSuccessHandler);
    },
    getListsHandler(query?: any): any {
      return getListsHandler(ApiUrl.feedback.get_lists, query);
    },
  };
  return (
    <FeedbackContext.Provider
      value={{
        isLoading,
        lists,
        ...handlers,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContextProvider;
