import React, { useState } from "react";
import { getIconsHandler } from "helpers";
import { IconTypeEnum } from "interfaces";
import { BoxModal } from "components";

function VideoListModel() {
  const [isOpen, setOpen] = useState(false);
  const VideoIcon = getIconsHandler(IconTypeEnum.VIDEO);
  const VideoList = [
    {
      link: "https://youtu.be/dF-tjVwwSi0",
      title: "How to create exam tutorial in Nepali",
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundImage:
            "linear-gradient(to right top, #0b297b, #691b81, #a60073, #d20055, #eb122b)",
        }}
        className={
          "flex items-center text-white gap-2  px-2 py-1 text-[12px] cursor-pointer bg-gray-100 rounded-md shadow-md"
        }
        onClick={() => setOpen(true)}
      >
        <VideoIcon />
        <span>Watch Tutorial Videos</span>
      </div>
      {isOpen && (
        <BoxModal
          title={"Watch Tutorial Videos"}
          closeHandler={() => setOpen(false)}
        >
          <div className={"flex flex-col gap-2"}>
            {VideoList?.map((e, key) => {
              return (
                <a
                  href={e?.link}
                  target={"_blank"}
                  key={key}
                  className={
                    "flex items-center gap-2 cursor-pointer underline text-blue-600"
                  }
                >
                  <VideoIcon />
                  <span className={"text-[14px]"}>{e?.title}</span>
                </a>
              );
            })}
          </div>
        </BoxModal>
      )}
    </>
  );
}

export default VideoListModel;
