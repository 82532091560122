import { createContext, useContext, useState } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";

import { DataContextInterface } from "./interface";
import { SystemContext } from "./SystemContext";
import { string } from "yup";

export const DataContext = createContext<DataContextInterface>({
  schools: [],
  seatDetails: [],
  dashboardStats: undefined,
  handlers: {
    getSchoolsHandler: function () {},
    setAppSetUpHandler(): any {},
    getDashboardStatsHandler: function () {},
    getSeatDetailsHandler(payload): any {},
    verifyEmailHandler(payload, onSuccessHandler?: () => any): any {},
    sendFeedbackHandler(payload: any, onSuccessHandler?: () => any): any {},
    toggleSchoolPublicStatusHandler(payload, onSuccessHandler) {},
    resendEmailVerificationCodeHandler(payload, onSuccessHandler) {},
    setPasswordHandler(payload, onSuccessHandler, onErrorHandler) {},
    validatePasswordHandler(
      payload: any,
      onSuccessHandler?: () => any,
      onErrorHandler?: (message: string) => any,
    ): any {},
    requestResetPasswordHandler(
      payload: any,
      onSuccessHandler?: () => any,
      onErrorHandler?: (message: string) => any,
    ): any {},
  },
});

const DataContextProvider = ({ children }: any) => {
  const {
    state: { profileDetails },
    handlers: {
      setLoadingHandler,
      setErrorHandler,
      setSuccessHandler,
      getCurrentProfileHandler,
      setProfileHandler,
    },
  } = useContext(SystemContext);
  const { getApi, postApi, putApi } = Api();
  const [schools, setSchools] = useState([]);
  const [seatDetails, setSeatDetails] = useState([]);
  const [dashboardStats, setDashboardStats] = useState(undefined);
  const isAdmin = profileDetails?.is_admin;
  const handlers = {
    getSeatDetailsHandler: async function (payload: any) {
      try {
        setSeatDetails([]);
        setLoadingHandler(true);
        const res = await postApi(ApiUrl.exam.post_position, payload);
        setSeatDetails(res?.data);
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
        setLoadingHandler(false);
      }
    },
    async toggleSchoolPublicStatusHandler(
      payload: any,
      onSuccessHandler?: () => any,
    ) {
      try {
        setLoadingHandler(true);
        await putApi(ApiUrl.school.put_togglePublicStatus, payload);
        setProfileHandler({
          ...profileDetails,
          public: !profileDetails?.public,
        });
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
        setLoadingHandler(false);
      }
    },
    getSchoolsHandler: async function () {
      try {
        const res = await getApi(ApiUrl.school.get_lists);
        setSchools(res?.data);
      } catch (err) {
      } finally {
      }
    },
    getDashboardStatsHandler: async function () {
      try {
        const res = await getApi(
          isAdmin
            ? ApiUrl.school.get_adminDashboardStats
            : ApiUrl.school.get_dashboardStats,
        );
        setDashboardStats(res?.data);
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
      }
    },
    sendFeedbackHandler: async function (payload: any, onSuccessHandler: any) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.feedback.post_create, payload);
        setSuccessHandler(true, "Feedback send successfully");
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
        setLoadingHandler(false);
      }
    },
    setAppSetUpHandler: async function () {
      try {
        await getApi(ApiUrl.school.get_setAppSetup);
        getCurrentProfileHandler();
      } catch (err) {
      } finally {
      }
    },
    async verifyEmailHandler(payload: any, onSuccessHandler: any) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.auth.post_verifyEmail, payload);
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
        setLoadingHandler(false);
      }
    },
    async resendEmailVerificationCodeHandler(
      payload: any,
      onSuccessHandler: any,
    ) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.auth.post_sendVerificationEmailCode, payload);
        setSuccessHandler(true, "Code send successfully");
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        setErrorHandler(true, err?.message);
      } finally {
        setLoadingHandler(false);
      }
    },
    async requestResetPasswordHandler(
      payload: any,
      onSuccessHandler?: () => any,
      onErrorHandler?: (message: string) => any,
    ) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.resetPassword.post_request, payload);
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        if (typeof onErrorHandler === "function") {
          onErrorHandler("Invalid email");
        }
      } finally {
        setLoadingHandler(false);
      }
    },
    async validatePasswordHandler(
      payload: any,
      onSuccessHandler?: () => any,
      onErrorHandler?: (message: string) => any,
    ) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.resetPassword.post_validate, payload);
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        if (typeof onErrorHandler === "function") {
          onErrorHandler(err?.message);
        }
      } finally {
        setLoadingHandler(false);
      }
    },
    async setPasswordHandler(
      payload: any,
      onSuccessHandler?: () => any,
      onErrorHandler?: (message: string) => any,
    ) {
      try {
        setLoadingHandler(true);
        await postApi(ApiUrl.resetPassword.post_resetPassword, payload);
        if (typeof onSuccessHandler === "function") {
          onSuccessHandler();
        }
      } catch (err) {
        if (typeof onErrorHandler === "function") {
          onErrorHandler(err?.message);
        }
      } finally {
        setLoadingHandler(false);
      }
    },
  };

  let contextValue = {
    schools,
    seatDetails,
    dashboardStats,
    handlers,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export default DataContextProvider;
