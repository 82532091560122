import React, { useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { SystemContext } from "context";
import { Form, Formik, FormikBag } from "formik";

import { MyButton, MyPasswordField, MyTextField } from "components";
import { PageLinks } from "routes";
import { VideoListModel } from "../content";
import { useApplicationHook } from "../useHooks";

function LoginPage() {
  const {
    handlers: { loginHandler, signupHandler },
  } = useContext(SystemContext);
  const location = useLocation();
  const isSignup = location.pathname.includes(PageLinks.signUp);

  const onSubmitHandler = (values: any, props: FormikBag<any, any>) => {
    isSignup
      ? signupHandler(values, () => {
          props.resetForm({
            values: {
              email: "",
              password: "",
              name: "",
            },
          });
          return;
        })
      : loginHandler(values);
  };
  const navigate = useNavigate();
  const { fullHeight } = useApplicationHook();

  return (
    <div style={{ height: fullHeight }} className={"h-full w-full "}>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{
          email: "",
          password: "",
          ...(isSignup ? { name: "" } : {}),
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required(" "),
          password: yup.string().required(" "),
          ...(isSignup ? { name: yup.string().required(" ") } : {}),
        })}
      >
        <Form
          className={
            " flex flex-col h-full w-full p-4 justify-between items-start"
          }
        >
          <div className={"flex flex-col items-center gap-20 w-full"}>
            <div className={"flex items-center justify-between w-full"}>
              <a href={"http://examseatplanner.com"} target={"_blank"}>
                <img
                  src={"/img/logo/logo.svg"}
                  className={"h-[60px] sm:h-[40px] object-contain"}
                  alt={"icon"}
                />
              </a>

              <VideoListModel />
            </div>
            <div className={"flex flex-col gap-5 w-[400px] sm:w-full"}>
              <div
                className={"text-center  text-[20px] uppercase font-bold mb-3"}
              >
                {isSignup ? "Create account for school" : "School Login"}
              </div>
              {isSignup ? (
                <MyTextField
                  name={"name"}
                  label={"School or College Name"}
                  isRequired
                  placeholder={"eg: IMS College"}
                />
              ) : (
                <></>
              )}{" "}
              <MyTextField
                name={"email"}
                label={"Email"}
                isRequired
                placeholder={"Enter email"}
              />
              <MyPasswordField
                name={"password"}
                label={"Password"}
                isRequired
                placeholder={"******"}
              />
              <div className={"flex w-full flex-col gap-2"}>
                <MyButton
                  name={isSignup ? "Sign up" : "Login"}
                  type={"submit"}
                />
                <div className={"text-[14px] text-blue-500 text-center w-full"}>
                  <span
                    className={"cursor-pointer underline"}
                    onClick={() => navigate(PageLinks.resetPassword.request)}
                  >
                    {" "}
                    Can't Login?
                  </span>
                </div>
              </div>
              <div className={"text-[14px] text-gray-500 w-full text-center"}>
                {isSignup ? "Have Account? " : "Don't have account? "}
                <span
                  className={"text-black cursor-pointer"}
                  onClick={() =>
                    navigate(isSignup ? PageLinks.login : PageLinks.signUp)
                  }
                >
                  {isSignup ? "Login" : "Sign Up"}
                </span>
              </div>{" "}
            </div>
          </div>
          <div
            className={
              "flex flex-col items-center gap-2 justify-center w-full  pb-10 "
            }
          >
            <div
              onClick={() => navigate(PageLinks.position)}
              className={
                "text-center text-[14px] underline text-blue-500 cursor-pointer"
              }
            >
              Find Your Exam Details!
            </div>
            <a
              target={"_blank"}
              href={"https://www.examseatplanner.com/privacy-policy.html"}
              className={"text-center cursor-pointer underline text-[14px]"}
            >
              Privacy and Policy
            </a>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default LoginPage;
