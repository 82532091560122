import React from "react";
import { createLayoutHandler } from "../helpers";
import { ClassRoomLayoutInterface } from "../interfaces";

interface PropsInterface {
  classRoomLayout: ClassRoomLayoutInterface[];
  row_count: number;
  column_count: number;
  onClickBench?(columnPosition: number, rowPosition: number): any;
}
function RoomLayoutContent({
  classRoomLayout,
  row_count,
  column_count,
  onClickBench,
}: PropsInterface) {
  const { columnName, layout } = createLayoutHandler(
    classRoomLayout,
    column_count,
  );
  const canClick = typeof onClickBench === "function";
  return (
    <div className={"flex flex-col gap-2 select-none"}>
      <div className={"text-[14px] font-semibold"}>Room Layout</div>
      <div className={"grid grid-cols-[45px_auto] w-full overflow-x-scroll "}>
        <div className={"flex flex-col gap-5 pt-[40px] shadow-lg"}>
          {new Array(row_count).fill("")?.map((parent, pKey) => {
            return (
              <div
                className={
                  "flex items-center justify-center h-[50px] text-[14px] text-gray-500 bg-gray-100 rounded-l-md px-2"
                }
                key={pKey}
              >
                R-{pKey + 1}
              </div>
            );
          })}
        </div>
        <div className={"flex gap-10 overflow-scroll w-full pl-2 pb-2"}>
          {layout?.map((parent, pKey) => {
            return (
              <div key={pKey} className={"flex-1"}>
                <div className={"text-center font-bold"}>
                  {columnName?.[pKey] || ""}
                </div>
                {/*seats*/}
                <div className={"flex flex-col items-center flex-1 gap-5 mt-5"}>
                  {parent?.map((child: any, cKey: number) => {
                    if (!child)
                      return (
                        <div
                          onClick={() =>
                            canClick && onClickBench(pKey + 1, cKey + 1)
                          }
                          key={cKey}
                          className={`cursor-pointer flex items-center justify-center rounded-md text-red-500 text-[12px] h-[50px] w-full ${canClick ? "border-2 border-dotted" : ""}`}
                        >
                          {canClick && "Removed"}
                        </div>
                      );
                    return (
                      <div
                        onClick={() =>
                          canClick && onClickBench(pKey + 1, cKey + 1)
                        }
                        key={cKey}
                        className={"flex flex-col gap-1 h-[50px] w-[100px]   "}
                      >
                        <div
                          className={
                            "flex items-center justify-center text-[12px] bg-[#A1662F] w-full h-[30px] rounded-t-md text-gray-200 cursor-pointer hover:scale-105 transition-all"
                          }
                          key={cKey}
                        >
                          Capacity: {child}
                        </div>
                        <div
                          className={"flex items-center justify-around gap-1"}
                        >
                          {new Array(child).fill("").map((chair, key) => {
                            return (
                              <div
                                key={key}
                                className={
                                  "w-[25px] h-[12px] bg-black rounded-b-md cursor-pointer hover:scale-105 transition-all"
                                }
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RoomLayoutContent;
