import { useField } from "formik";
import "./styles.css";
import { MyTextFieldInterface } from "./interfaces";

export default function MyTextField({
  label,
  isRequired,
  onChangeCallbackHandler,
  ...props
}: MyTextFieldInterface) {
  let [field, meta, helpers] = useField(props);
  let isError = meta.touched && meta.error;
  const onChangeHandler = (e) => {
    e?.preventDefault();
    let value = e?.target?.value;
    if (props?.type == "number") {
      value = parseInt(value);
    }
    if (typeof onChangeCallbackHandler === "function") {
      onChangeCallbackHandler(value);
    }
    helpers.setValue(value);
  };
  return (
    <div className={`input_container`}>
      {label && (
        <label className={`input_label`}>
          {label}
          {isRequired && <span className={"text-tRed "}>*</span>}
        </label>
      )}
      <div className={"flex flex-col gap-2"}>
        <input
          {...field}
          {...props}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={onChangeHandler}
          type={props?.type || "text"}
          className={`${
            props?.disabled && "bg-gray-100 cursor-not-allowed"
          }  outline-none min-h-[35px] focus:border-[#2680EB] ${
            isError ? "border-tRed" : "border-[#C0C0C0]"
          }  text-[16px] 
           ${props?.className} `}
          value={field.value}
        />
        {isError && meta?.error?.trim() && (
          <span className="input_textError">* {meta.error}</span>
        )}
      </div>
    </div>
  );
}
