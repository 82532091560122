import { useContext } from "react";
import { SystemContext } from "context";
import { useApplicationHook } from "useHooks";

export default function BlockedPage() {
  const {
    state: { profileDetails },
    handlers: { logoutHandler },
  } = useContext(SystemContext);
  const { fullHeight } = useApplicationHook();
  return (
    <div
      style={{ height: fullHeight }}
      className="flex h-[100vh] gap-[40px] items-center justify-center"
    >
      <div className="gap-3 flex flex-col  items-start">
        <h1 className="font-bold text-xl">
          Dear {profileDetails?.name || ""},
        </h1>
        <p className={"text-left"}>
          Your account has been blocked by Admin.
          <br /> Please contact examseatplanner@gmail.com
        </p>
        <div
          className={"text-center cursor-pointer text-blue-600"}
          onClick={() => logoutHandler(true)}
        >
          Logout
        </div>
      </div>
    </div>
  );
}
