import React from "react";
import { useField } from "formik";
import "./styles.css";
import { MyCheckFieldInterface } from "./interfaces";
import { IconTypeEnum } from "interfaces";
import { getIconsHandler } from "helpers";

export default function MyCheckField({
  label,
  defaultValue,
  iconType,
  valueMustCheck,
  ...props
}: MyCheckFieldInterface) {
  const [field, meta, helpers] = useField(props);
  const isMultiple = Array.isArray(field?.value);
  const haveDefaultValue = typeof defaultValue !== "undefined";
  const radioType = iconType === IconTypeEnum.RADIO;
  let CheckIcon = getIconsHandler(IconTypeEnum.CHECK);
  let UnCheckIcon = getIconsHandler(IconTypeEnum.UN_CHECK);
  if (iconType === IconTypeEnum.RADIO) {
    CheckIcon = getIconsHandler(IconTypeEnum.RADIO);
    UnCheckIcon = getIconsHandler(IconTypeEnum.RADIO_CIRCLE);
  }
  if (iconType === "Toggle") {
    CheckIcon = getIconsHandler(IconTypeEnum.TOGGLE_ON);
    UnCheckIcon = getIconsHandler(IconTypeEnum.TOGGLE_OFF);
  }
  const isSelected = () => {
    let status;
    if (isMultiple) {
      status = field?.value?.some((e) => e == defaultValue);
    } else {
      if (haveDefaultValue) {
        status = defaultValue == field?.value;
      } else {
        status = field?.value != false;
      }
    }

    return status;
  };
  const onClickHandler = () => {
    if (isMultiple) {
      let tempField = [...field?.value];
      let isAlready = tempField?.some((e) => e == defaultValue);
      if (isAlready) {
        helpers.setValue(tempField?.filter((e) => e !== defaultValue));
      } else {
        tempField?.push(defaultValue);
        helpers.setValue(tempField);
      }
    } else {
      if (haveDefaultValue) {
        if (!radioType && !valueMustCheck && field?.value === defaultValue) {
          helpers.setValue("");
        } else {
          helpers.setValue(defaultValue);
        }
      } else {
        helpers.setValue(isSelected() ? false : true);
      }
      return;
    }
  };

  return (
    <div
      className={"flex items-center gap-2 cursor-pointer select-none"}
      onClick={onClickHandler}
    >
      <div
        className={`${iconType === "Toggle" ? "text-[28px]" : "text-[20px]"}`}
      >
        {isSelected() ? (
          <CheckIcon
            data-testid={"check-input-checkbox-icon"}
            className={"text-tBlue"}
          />
        ) : (
          <UnCheckIcon
            data-testid={"check-input-checkbox-icon"}
            className={"text-gray-300"}
          />
        )}
      </div>
      <span className={`input_label text-[16px]`}>{label}</span>
    </div>
  );
}
