import React, { useContext } from "react";
import { getIconsHandler } from "helpers";
import { IconTypeEnum, TopNavPropsInterface } from "interfaces";
import { useNavigate } from "react-router-dom";
import { DataContext, SystemContext } from "context";
import { FeedbackModel, VideoListModel } from "content";
import { MyToggleButton } from "components";
function TopNavComponent({
  title,
  backPath,
  rightChildren,
}: TopNavPropsInterface) {
  const navigate = useNavigate();
  const {
    state: { profileDetails },
    handlers: { logoutHandler },
  } = useContext(SystemContext);
  const {
    handlers: { toggleSchoolPublicStatusHandler },
  } = useContext(DataContext);
  const BackIcon = getIconsHandler(IconTypeEnum.BACK);
  const LogoutIcon = getIconsHandler(IconTypeEnum.LOGOUT);
  const isAdmin = profileDetails?.is_admin;
  return (
    <div className={`w-full flex items-center justify-between px-4 `}>
      <div className={"flex items-center gap-2"}>
        {backPath && (
          <div
            onClick={() => navigate(backPath)}
            className={
              "text-black cursor-pointer hover:bg-gray-100 py-3 px-2 rounded-md"
            }
          >
            <BackIcon className={"text-[20px]"} />
          </div>
        )}
        {title ? (
          <span
            className={"font-bold text-[20px]   whitespace-nowrap truncate"}
          >
            {title}
          </span>
        ) : (
          <img
            src={"/img/logo/logo.svg"}
            alt={"logo"}
            className={" h-[45px]"}
          />
        )}
      </div>
      <div className={"flex items-center justify-center gap-2 "}>
        {rightChildren}
        {!title ? (
          <div className={"flex items-center gap-5 "}>
            {!isAdmin ? (
              <>
                <div
                  onClick={() => toggleSchoolPublicStatusHandler({})}
                  className={
                    "flex items-center gap-2 cursor-pointer text-[14px]"
                  }
                >
                  <span>Student Look Up</span>
                  <MyToggleButton
                    value={!!profileDetails?.public}
                    changeHandler={() => {}}
                  />
                </div>
                <div className={"sm:hidden"}>
                  <FeedbackModel />
                </div>
                <div className={"sm:hidden"}>
                  <VideoListModel />
                </div>
              </>
            ) : (
              <div
                className={
                  "text-[12px] bg-red-500 rounded-sm text-white px-2 py-1"
                }
              >
                Logged in as Super Admin
              </div>
            )}
            <div
              onClick={() => logoutHandler(true)}
              className={
                "flex items-center gap-1 bg-white  text-[14px]  cursor-pointer"
              }
            >
              <LogoutIcon className={"text-[18px]"} /> Logout
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TopNavComponent;
