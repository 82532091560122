import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { StudentGroupContext } from "context";
import {
  BoxModal,
  MyButton,
  MyMoreOptionButton,
  TableComponent,
} from "components";
import {
  HomeTabEnum,
  IconTypeEnum,
  ParamsStringEnum,
  StudentInterface,
} from "interfaces";
import { getIconsHandler } from "../helpers";

function StudentGroupDetailsPage() {
  const { group_id } = useParams<ParamsStringEnum>();
  const { details, isDetailsLoading, getDetailsHandler, deleteStudentHandler } =
    useContext(StudentGroupContext);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    getDetailsHandler(group_id);
  }, [group_id]);
  useEffect(() => {
    setSelectedData([]);
  }, [details]);
  return (
    <PageTemplate
      isLoading={isDetailsLoading}
      title={details?.name || "Student Group Details"}
      rightChildren={
        details &&
        !isDetailsLoading && (
          <div>
            <AddStudentModel groupId={group_id} />
          </div>
        )
      }
      backPath={PageLinks.home(HomeTabEnum.GROUPS)}
    >
      {selectedData?.length > 0 && (
        <div className={"flex items-center gap-2"}>
          <span>{selectedData?.length} students selected</span>
          <div>
            <MyButton
              size={"sm"}
              name={"Delete Students"}
              colorType={"danger"}
              onClick={() =>
                deleteStudentHandler(
                  {
                    students: selectedData?.map((e) => e?.id) || [],
                  },
                  () => {
                    getDetailsHandler(group_id);
                    setSelectedData([]);
                  },
                )
              }
            />
          </div>
        </div>
      )}
      <div className={"overflow-x-scroll w-full"}>
        <TableComponent
          rowSelectConfiguration={{
            onSelectRows(data) {
              setSelectedData(data);
            },
          }}
          column={[
            {
              title: "SN.",
              render(renderData: StudentInterface, key: number) {
                return <span>{key + 1}.</span>;
              },
            },
            {
              title: "Name",
              render(renderData: StudentInterface) {
                return <span>{renderData?.name}</span>;
              },
            },
            {
              title: "Symbol No.",
              render(renderData: StudentInterface) {
                return <span>{renderData?.symbol_no}</span>;
              },
            },

            {
              title: "",
              render(renderData: StudentInterface) {
                return (
                  <MyMoreOptionButton
                    options={[
                      {
                        name: "Delete",
                        handler() {
                          deleteStudentHandler(
                            { students: [renderData?.id] },
                            () => {
                              getDetailsHandler(group_id);
                            },
                          );
                        },
                      },
                    ]}
                  />
                );
              },
            },
          ]}
          dataSource={details?.students || []}
        />
      </div>
    </PageTemplate>
  );
}

const AddStudentModel = ({ groupId }: { groupId: string }) => {
  const [isOpen, setOpen] = useState(false);
  const FormIcon = getIconsHandler(IconTypeEnum.FORM);
  const ExcelIcon = getIconsHandler(IconTypeEnum.EXCEL);
  const navigate = useNavigate();
  return (
    <>
      <MyButton
        onClick={() => setOpen(true)}
        isOutline
        name={"Add Students"}
        colorType={"blue"}
        iconType={IconTypeEnum.ADD}
      />
      {isOpen && (
        <BoxModal closeHandler={() => setOpen(false)}>
          <div className={"grid grid-cols-2 gap-5"}>
            {[
              {
                name: "Fill Form",
                path: PageLinks.students.create(groupId),
                icon: FormIcon,
              },
              {
                name: "Upload Excel",
                path: PageLinks.students.uploadExcel(groupId),
                icon: ExcelIcon,
              },
            ].map((e, key) => {
              const Icon = e?.icon;
              return (
                <div
                  key={key}
                  onClick={() => navigate(e?.path)}
                  className={
                    "flex flex-col gap-2 text-[14px] hover:bg-gray-100 items-center justify-center bg-white border cursor-pointer rounded-md h-[80px]"
                  }
                >
                  <Icon className={"text-[25px]"} />
                  {e?.name}
                </div>
              );
            })}
          </div>
        </BoxModal>
      )}
    </>
  );
};

export default StudentGroupDetailsPage;
