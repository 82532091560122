import React from "react";

import { TopNavComponent } from "./components";
import { PageTemplateInterface } from "../interfaces";

function AppTemplate({
  children,
  title,
  showProfile,
  showAddIcon,
  backPath,
  rightChildren,
  hideNavBorder,
  hideTopNav,
  isFullWidth,
  isLoading,
}: PageTemplateInterface) {
  return (
    <div className={`h-full overflow-scroll  hideScroll `}>
      <div
        className={` w-full grid  ${hideTopNav ? "" : "grid-rows-[70px_auto]"}  h-full overflow-scroll hideScroll  order-2 sm:order-1`}
      >
        {!hideTopNav && (
          <TopNavComponent
            rightChildren={rightChildren}
            showProfile={showProfile}
            showAddIcon={showAddIcon}
            title={title}
            backPath={backPath}
            hideNavBorder={hideNavBorder}
          />
        )}

        <div
          className={`flex flex-col items-center h-full w-full overflow-scroll hideScroll ${hideTopNav ? "" : " px-4 pt-4"} `}
        >
          {!isLoading ? (
            <div
              className={`flex flex-col gap-5 ${isFullWidth ? "" : "max-w-[800px]"}  w-full h-full  overflow-scroll hideScroll pb-5 sm:pb-2 px-2`}
            >
              {children}
            </div>
          ) : (
            <div className={"text-center text-[14px] text-gray-500"}>
              Please wait loading...
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppTemplate;
