export enum LS_NAME {
  accessToken = "accessToken",
}

export enum BaseUrlType {
  DEFAULT = "default",
}

export enum QueryStringEnum {
  TAB = "tab",
}

export enum ParamsStringEnum {
  SCHOOL_ID = "school_id",
  CLASS_ID = "class_id",
  EXAM_ID = "exam_id",
  GROUP_ID = "group_id",
  TYPE = "type",
}

export enum IconTypeEnum {
  RELOAD = "RELOAD",
  EXAM = "EXAM",
  PRINT = "PRINT",
  CREATE_GROUP = "CREATE_GROUP",
  CREATE_ROOM = "CREATE_ROOM",
  CREATE_EXAM = "CREATE_EXAM",
  STUDENT_GROUP = "STUDENT_GROUP",
  RATING = "RATING",
  TRANSLATE = "TRANSLATE",
  CLASSROOM = "CLASSROOM",
  MESSAGE = "MESSAGE",
  SEND = "SEND",
  SEARCH = "SEARCH",
  ERROR = "ERROR",
  ROBOT = "ROBOT",
  FULL_SCREEN_OPEN = "FULL_SCREEN_OPEN",
  FULL_SCREEN_CLOSE = "FULL_SCREEN_CLOSE",
  INFO = "INFO",
  DELETE = "DELETE",
  PAUSE = "PAUSE",
  EXCEL = "EXCEL",
  EDIT = "EDIT",
  FORM = "FORM",
  LIST = "LIST",
  HOME = "HOME",
  ADD = "ADD",
  USER = "USER",
  SCROLL = "SCROLL",
  BACK = "BACK",
  CONTRIBUTE = "CONTRIBUTE",
  SETTING = "SETTING",
  LOVE = "LOVE",
  LOVE_FILL = "LOVE_FILL",
  FEEDBACK = "FEEDBACK",
  VIDEO = "VIDEO",
  MUSIC = "MUSIC",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  CLOSE = "CLOSE",
  DASHBOARD = "DASHBOARD",
  CHECK = "CHECK",
  RADIO = "RADIO",
  UN_CHECK = "UN_CHECK",
  RADIO_CIRCLE = "RADIO_CIRCLE",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
}

export enum InputSizeEnum {
  SM = "sm",
  MD = "md",
  LG = "lg",
}
export enum HomeTabEnum {
  EXAMS = "EXAMS",
  ROOMS = "ROOMS",
  GROUPS = "GROUPS",
}
export enum AdminDashboardTabEnum {
  SCHOOL = "SCHOOL",
  FEEDBACK = "FEEDBACK",
  LOGS = "LOGS",
}
export enum LogsTypeEnum {
  APPLICATION = "APPLICATION",
  SYSTEM = "SYSTEM",
  REQUEST = "REQUEST",
}

export enum ResetPasswordParamsEnum {
  CHECK_EMAIL = "check-email",
  VALIDATE_TOKEN = "validate-token",
  VALIDATION_ERROR = "validation-error",
  PASSWORD_RESET_SUCCESSFULLY = "password-reset-success",
}
