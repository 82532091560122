import { useContext, useEffect } from "react";
import { createPortal } from "react-dom";

import { SystemContext } from "context";
import { getIconsHandler } from "helpers";
import { IconTypeEnum } from "interfaces";

import { BoxModalInterface } from "./interfaces";
import { useApplicationHook } from "../../useHooks";

export default function ModalComponent({
  title,
  closeHandler,
  children,
  isCloseAuto,
  titleRightChildren,
  isFull,
}: BoxModalInterface) {
  const { state, handlers } = useContext(SystemContext);
  const CloseIcon = getIconsHandler(IconTypeEnum.CLOSE);
  const { fullHeight } = useApplicationHook();
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeHandler();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, []);

  useEffect(() => {
    if (state.isActionSuccess && isCloseAuto) {
      closeHandler();
      handlers.setSuccessHandler(false, "");
    }
  }, [state.isActionSuccess]);
  return createPortal(
    <div
      style={{
        height: fullHeight + "px",
      }}
      className="fixed flex items-start justify-center bg-black bg-opacity-30  w-screen left-0 top-0 z-[999] "
    >
      <div
        onClick={() => typeof closeHandler === "function" && closeHandler()}
        className={`  w-[500px] sm:w-full sm:mx-3
         flex items-center  h-full bg-opacity-30 `}
      >
        <div
          className={"bg-white w-full rounded-lg p-2 px-4"}
          onClick={(e) => e?.stopPropagation()}
        >
          {(title ||
            typeof closeHandler === "function" ||
            titleRightChildren) && (
            <div className="flex justify-between items-center">
              {title ? (
                <span className={" text-[18px] text-primary"}>{title}</span>
              ) : (
                <div></div>
              )}
              <>
                {titleRightChildren && titleRightChildren}
                {closeHandler && (
                  <div className={"hover:bg-gray-100 p-2 rounded-lg"}>
                    <CloseIcon
                      className={"text-[20px] text-primary cursor-pointer  "}
                      onClick={() => closeHandler()}
                    />
                  </div>
                )}
              </>
            </div>
          )}
          <div className="py-5  max-h-[80vh] min-h-[20vh] overflow-y-scroll">
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("popup-root"),
  );
}
