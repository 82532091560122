import {
  ClassRoomLayoutInterface,
  ExamLayoutInterface,
  ExamLayoutObjectInterface,
} from "../interfaces";

export function createLayoutHandler(
  details: ClassRoomLayoutInterface[],
  columnCount: number,
) {
  try {
    let layout = [];
    let columnName = {};

    new Array(columnCount).fill("").forEach((e) => {
      layout.push([]);
    });
    details?.map((e) => {
      let rowPosition = e?.row_position - 1;
      let columnPosition = e?.column_position - 1;
      columnName[columnPosition] = e?.column_name || "";
      if (columnPosition >= 0 && rowPosition >= 0) {
        layout[columnPosition][rowPosition] = e?.capacity;
      }
    });

    return {
      layout,
      columnName,
    };
  } catch (err) {
    return {
      layout: [],
      columnName: {},
    };
  }
}
export function downloadFileHandler(url: string, filename: string) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", filename);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}
export function getSeatFormatHandler(examLayout: ExamLayoutInterface) {
  return `Column-${examLayout?.column_name} \t Row-${examLayout?.row_position} \t Position-${examLayout?.row_position} `;
}

export function getClassroomLayoutObject(
  examLayout: ExamLayoutInterface[],
): ExamLayoutObjectInterface {
  let data: ExamLayoutObjectInterface = {};
  examLayout?.forEach((e) => {
    data[e?.class_id] = { ...data[e?.class_id] } || {};
    data[e?.class_id][e?.column_position] =
      {
        ...data[e?.class_id][e?.column_position],
      } || {};
    data[e?.class_id][e?.column_position][e?.row_position] =
      {
        ...data[e?.class_id][e?.column_position][e?.row_position],
      } || {};
    data[e?.class_id][e?.column_position][e?.row_position][e?.position] =
      {
        ...data[e?.class_id][e?.column_position][e?.row_position][e?.position],
      } || e;
    data[e?.class_id][e?.column_position][e?.row_position][e?.position] = e;
  });
  return data;
}
